/* layout */
	.layer-closed {position:fixed;left:0;top:0;right:0;bottom:0;z-index:100;overflow:hidden;text-indent:-999.9rem;background-color:transparent;}
	.layer-closed02 {position:fixed;left:0;top:0;right:0;bottom:0;z-index:20003;overflow:hidden;text-indent:-999.9rem;background-color:transparent;}
	#wrap {position:relative;height:100%;}
	.flx-sti-wrap {
		display:-webkit-box;display:-moz-box;display:-ms-flexbox;display:-webkit-flex;display:flex;
		-moz-box-orient:vertical;-webkit-box-orient:vertical;box-orient:vertical;-webkit-flex-direction:column;flex-direction:column;
		height:100%;
	}
	.flx-sti-cont {-webkit-box-flex:1;-ms-flex:1 0 auto;flex:1 0 auto;}
	.flx-sti-cont.full-h-mode {height:calc(100% - 5.4rem);}
	.no-main-nav .flx-sti-cont.full-h-mode {height:calc(100%);}
	.flx-sti-footer {flex-shrink:0;padding-bottom:5.4rem;}
	.flx-sti-footer.fixed {position:fixed;right:0;bottom:0;z-index:100;width:auto;}
	.flx-sti-footer.sticky-mode {position:sticky;left:0;right:0;bottom:0;z-index:100;background-color:#FFF;}
  .flx-sti-footer.sticky-mode::before {
    content:'';position:absolute;left:0;top:-3rem;right:0;z-index:2;
    height:3rem;background:linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%);
  }
	.flx-sti-footer.full-w-mode {width:100%;}
	.no-main-nav .flx-sti-footer {padding-bottom:0;}
	.header, .other-header {position:fixed;left:0;top:0;z-index:106;width:100%;background-color:#fff;}
	.no-fixed .header {position:static;}
	.no-fixed	.sub-container {padding-top:0;}
	.header.alt-bg {background-color:#f3f4f5;}
	.header h1.only-bi-mode {padding:1.6rem 0;}
	.header h1 .bi {display:block;width:14.2rem;height:2rem;margin:0 auto;}
	.other-header {background-color:transparent;}
	.sub-container {height:100%;padding-bottom:3rem;padding-top:5.2rem;}
	.sub-container.add-db-pdt {padding-top:10.4rem;}
	.sub-container.add-tab-fixed {padding-top:11.4rem;}
	.more-pd-gap {padding-bottom:6rem;}
	.fixed-case-cont {height:100%;padding-top:5.2rem;padding-bottom:10.2rem;}
	.no-main-nav .fixed-case-cont {padding-bottom:5.4rem;}
	.other-container {min-height:100vh;padding-top:5.2rem;}
	@supports (height: 100dvh) {
		.other-container {
			height: 100dvh;
		}
	}
	@-webkit-keyframes toast-slide-in-bottom {
		0%		{-webkit-transform:translateY(200%);transform:translateY(200%);}
		100%	{-webkit-transform:translateY(0);transform:translateY(0);}
	}
	@keyframes toast-slide-in-bottom {
		0%		{-webkit-transform:translateY(200%);transform:translateY(200%);}
		100%	{-webkit-transform:translateY(0);transform:translateY(0);}
	}
	.toast-popup {position:fixed;left:1.6rem;right:1.6rem;bottom:7rem;z-index:31000;}
	.toast-popup p {
		display:-webkit-box;display:-moz-box;display:-ms-flexbox;display:-webkit-flex;display:flex;
		-webkit-box-align:center;-moz-box-align:center;-ms-box-align:center;-webkit-align-items:center;align-items:center;
		-webkit-box-pack:center;-moz-box-pack:center;-ms-box-pack:center;-webkit-justify-content:center;justify-content:center;
		overflow:hidden;min-height:5.2rem;padding:1rem 1.6rem;border-radius:0.4rem;color:#fff;font-size:1.3rem;text-align:center;line-height:1.8rem;word-break:keep-all;background-color:rgba(0,0,0,0.85);
	}
	.toast-popup p.other-txt {display:block;text-align:left;padding:1.8rem 1.6rem;}
	.toast-popup .inner-btn {position:absolute;right:1.6rem;top:50%;z-index:5;font-size:1.3rem;line-height:1.9rem;color:#00b2c7;-webkit-transform:translateY(-50%);transform:translateY(-50%);background-color:transparent;}
	/* scroll overlay */
	.obj-video {width:112%;height:112%;object-fit:cover;}
	.obj-video.landscape {object-fit:contain;background-color:#000;}
	.ui-scr-overlay {height:100%;}
	.ui-scr-overlay.my-page {margin-top:37.7rem;}
	.ui-scr-overlay.search {margin-top:37.7rem;}
	.floor-cover {
		display:-webkit-box;display:-moz-box;display:-ms-flexbox;display:-webkit-flex;display:flex;
		-webkit-box-align:center;-moz-box-align:center;-ms-box-align:center;-webkit-align-items:center;align-items:center;
		-webkit-box-pack:center;-moz-box-pack:center;-ms-box-pack:center;-webkit-justify-content:center;justify-content:center;
		position:fixed;top:0;left:0;width:100%;height:100%;
		background-color:#f1f3f5 ;
	}
	.floor-cover::before {content:'';position:fixed;top:0;left:0;width:100%;height:100%;background-color:rgba(0,0,0,0.1);}
	.floor-cover.inactive {display:none;}
	.scr-cover {
		display:-webkit-box;display:-moz-box;display:-ms-flexbox;display:-webkit-flex;display:flex;
		-moz-box-orient:vertical;-webkit-box-orient:vertical;box-orient:vertical;-webkit-flex-direction:column;flex-direction:column;
		position:relative;width:100%;min-height:100%;top:100%;opacity:0;-webkit-transition:all 300ms;transition:all 300ms;background-color:#fff;
	}
	.scr-cover.active {z-index:99;opacity:1;top:0;}
	.scr-cover .scr-outer {
		-webkit-box-flex:1;ms-flex:1;flex:1;
		display:-webkit-box;display:-moz-box;display:-ms-flexbox;display:-webkit-flex;display:flex;
		-moz-box-orient:vertical;-webkit-box-orient:vertical;box-orient:vertical;-webkit-flex-direction:column;flex-direction:column;
		padding-top:5.2rem;padding-bottom:5.4rem;background-color:#fff;
	}
	.scr-cover .scr-outer.no-tab-nav, .list-cont-wrap.no-tab-nav {padding-top:3rem;}
	.scr-cover .scr-outer.no-tab-nav.normal-type, .list-cont-wrap.no-tab-nav.normal-type {padding-top:1.5rem;}
	.floor-cont-box {position:absolute;left:0;top:0;width:100%;height:100%;z-index:1;}
	.dimmed-layer {position:absolute;left:0;top:0;width:100%;height:100%;background-color:#000;}
	.scr-lock .floor-cont-box {display:none;}
	.ui-scr-overlay .btn-handle {position:fixed;bottom:5rem;z-index:10;overflow:hidden;width:100%;height:2.8rem;text-indent:-999.9rem;text-align:center;background:#fff url('../images/btn_handle.png') 50% 50% no-repeat;background-size:2rem;}
	.ui-scr-overlay.no-main-nav .btn-handle {bottom:0;}
	.no-main-nav .scr-cover .scr-outer {padding-bottom:0;}
	.no-main-nav .list-cont-wrap {padding-bottom:0;}
	/* copyright */
	.foot-utility {
		display:-webkit-box;display:-moz-box;display:-ms-flexbox;display:-webkit-flex;display:flex;
		-webkit-box-pack:center;-moz-box-pack:center;-ms-box-pack:center;-webkit-justify-content:center;justify-content:center;
		font-size:1.2rem;line-height:1.6rem;letter-spacing:-0.5px;
	}
	.foot-utility .item {position:relative;padding-left:0.8rem;margin-left:0.8rem;}
	.foot-utility .item::before {content:'';position:absolute;left:0;top:0;bottom:0;width:1px;height:0.9rem;margin:auto;background-color:#ced4da;}
	.foot-utility .item:first-of-type {padding-left:0;margin-left:0;}
	.foot-utility .item:first-of-type::before {display:none;}
	.foot-utility a, .foot-utility button, .foot-utility .version {display:inline-block;line-height:1.6rem;vertical-align:top;color:#868e96;}
	.foot-utility .version {padding-left:1.6rem;background:url('../images/ico_logo_vcoloring.png') 0 50% no-repeat;background-size:1.4rem;}
	.foot-utility .btn-update {margin-left:0.6rem;color:#00b2c7;}
	.copyright {padding-bottom:2rem;text-align:center;color:#495057;font-size:1.2rem;line-height:2rem;}
	.copyright .btn-tg {position:relative;display:inline-block;padding-right:1.3rem;font-size:1.2rem;line-height:3.4rem;vertical-align:top;}
	.copyright .btn-tg::after {content:'';position:absolute;right:0;top:50%;width:1.2rem;height:1.2rem;margin-top:-0.6rem;-webkit-transition:transform 300ms;transition:transform 300ms;background:url('../images/btn_expand_down.png') no-repeat;background-size:1.2rem;}
	.copyright dt {margin-bottom:0.6rem;}
	.copyright dd {opacity:0;overflow:hidden;height:0;-webkit-transition:all 300ms;transition:all 300ms;}
	.copyright dd p span {color:#adb5bd;}
	.copyright dd p a, .copyright dd p button {display:inline-block;padding-right:1.2rem;vertical-align:top;line-height:2rem;background:url('../images/btn_footer_arrow.png') 100% 50% no-repeat;background-size:1.2rem;}
	.copyright .active .btn-tg::after {-webkit-transform:rotate(180deg);transform:rotate(180deg);}
	.copyright .active dd {opacity:1;overflow:visible;height:6rem;}
/* solo title box */
	.sol-tit-box {position:relative;overflow:hidden;height:5.2rem;padding:0 1.6rem;}
	.sol-tit-box.sort-ellipsis h1 {overflow:hidden;width:75%;text-overflow:ellipsis;white-space:nowrap;}
	.sol-tit-box.txt-ellipsis h1 {overflow:hidden;width:100%;text-overflow:ellipsis;white-space:nowrap;}
	.btn-sorting-wrap {
		display:-webkit-box;display:-moz-box;display:-ms-flexbox;display:-webkit-flex;display:flex;
		-webkit-box-align:center;-moz-box-align:center;-ms-box-align:center;-webkit-align-items:center;align-items:center;
		position:absolute;right:0.8rem;top:5.8rem;z-index:100;
	}
	.btn-sorting-wrap .btn-txt {padding:1rem 0.8rem;font-weight:400;font-size:1.4rem;line-height:2rem;color:#495057;white-space:nowrap;}
	.btn-sorting-wrap .btn-sorting {padding:1rem 0.8rem 1rem 1.6rem;font-weight:400;font-size:1.4rem;line-height:2rem;color:#495057;background:url('../images/btn_sorting.png') 0 50% no-repeat;background-size:1.2rem;}
	.btn-sorting-wrap .btn-txt:disabled {opacity:0.7;}
	.btn-sorting-wrap .inner-popmenu {display:none;position:absolute;right:0.8rem;top:5rem;z-index:10;width:15rem;border-radius:0.6rem;box-shadow:0 0.2rem 1.8rem rgba(0,0,0,0.2);background-color:#fff;}
	.btn-sorting-wrap .inner-popmenu.active {display:block;}
	.btn-sorting-wrap .inner-popmenu button, .btn-sorting-wrap .inner-popmenu a {display:block;width:100%;height:4.4rem;padding-left:1.6rem;line-height:4.4rem;font-size:1.4rem;}
	.btn-sorting-wrap .inner-popmenu button:active, .btn-sorting-wrap .inner-popmenu a:active {background-color:rgba(0,0,0,0.1);}
	.btn-sorting-wrap .inner-popmenu .selected {color:#00b2c7;}
	iframe.sol-tit-box {height:auto;padding:0;}
	.sol-tit-box h1 {font-weight:700;font-size:1.8rem;line-height:5.2rem;}
	.sol-tit-box.other-st {height:3.6rem;margin-top:1rem;margin-bottom:0.8rem;}
	.sol-tit-box.other-st h1 {overflow:hidden;height:3.6rem;font-size:2.4rem;line-height:3.6rem;white-space:nowrap;text-overflow:ellipsis;}
	.bg-gray .sol-tit-box.other-st {padding-top:1rem;height:4.6rem;margin-top:0;}
	.sol-tit-box.other-st.flag-mode {overflow:visible;}
	.sol-tit-box.other-st.flag-mode h1 {overflow:visible;position:relative;display:inline-block;height:3.6rem;vertical-align:top;}
	.sol-tit-box.other-st.flag-mode h1 .flag {
		position:absolute;right:-3.3rem;top:-0.6rem;height:1.8rem;padding:0 0.6rem;border-radius:0.3rem;font-weight:900;font-size:1rem;line-height:1.8rem;color:#fff;
		background:#4545ff;
		background:-moz-linear-gradient(left,  #4545ff 0%, #992dff 100%);
		background:-webkit-linear-gradient(left,  #4545ff 0%,#992dff 100%);
		background:linear-gradient(to right,  #4545ff 0%,#992dff 100%);
		filter:progid:DXImageTransform.Microsoft.gradient( startColorstr='#4545ff', endColorstr='#992dff',GradientType=1 );
	}
	.sol-tit-box.other-st.multi-line {height:auto;margin-bottom:3rem;}
	.sol-tit-box.other-st.multi-line h1 {height:auto;line-height:3.6rem;text-overflow:clip;white-space:normal;}
	.sol-tit-box.other-st.multi-line h1.alter-st {height:auto;line-height:3.2rem;}
	.sol-tit-box.other-st.all-visible, .sol-tit-box.other-st.all-visible h1 {overflow:visible;}
	.sol-tit-box .inner-txt-wrap {position:relative;display:inline-block;vertical-align:top;}
	.sol-tit-box .inner-txt-wrap .btn-tip {display:inline-block;width:2rem;height:2rem;margin-top:0.9rem;overflow:hidden;vertical-align:top;text-indent:-999.9rem;background:url('../images/ico_info.png') no-repeat;background-size:2rem;}
	.sol-tit-box .inner-txt-wrap .inner-bubbler {display:none;position:absolute;left:-9.4rem;top:4.5rem;z-index:2;width:32.8rem;padding:1.6rem;border-radius:0.6rem;font-size:1.2rem;line-height:1.7rem;color:#fff;white-space:normal;font-weight:400;background-color:#212529;}
	.sol-tit-box .inner-txt-wrap .inner-bubbler::after {content:'';position:absolute;top:-1.1rem;left:9.7rem;z-index:1;display:block;width:0;border-style:solid;border-width:0 0.6rem 1.2rem;border-color:#212529 transparent;}
	.sol-tit-box .inner-txt-wrap .inner-bubbler .btn-inner-close {position:absolute;right:0;top:0;z-index:2;overflow:hidden;width:4.4rem;height:4.4rem;text-indent:-999.9rem;background:url('../images/btn_close05.png') 50% 50% no-repeat;background-size:2rem;}
	.sol-tit-box .inner-txt-wrap .inner-bubbler .block-item {position:relative;display:block;padding-left:0.8rem;margin-top:0.6rem;}
	.sol-tit-box .inner-txt-wrap .inner-bubbler .block-item:first-of-type {margin-top:0;}
	.sol-tit-box .inner-txt-wrap .inner-bubbler .block-item::before {content:'';position:absolute;left:0;top:0.5rem;width:4px;height:4px;border-radius:4px;background-color:#fff;}
	.sol-tit-box .inner-txt-wrap .inner-bubbler.active {display:block;}
	.sol-tit-box.etc-st {
		display:-webkit-box;display:-moz-box;display:-ms-flexbox;display:-webkit-flex;display:flex;
		-webkit-box-align:center;-moz-box-align:center;-ms-box-align:center;-webkit-align-items:center;align-items:center;
		overflow:visible;height:5rem;margin-bottom:-0.5rem;
	}
	.sol-tit-box.other-st + .sol-tit-box.etc-st {margin-top:-0.8rem;}
	.sol-tit-box.other-st.multi-line + .sol-tit-box.etc-st,
	.sol-tit-box.other-st.multi-line + .list-ctrl-box.refresh-st {margin-top:-3rem;}
	.sol-tit-box.etc-st .btn-sorting-wrap {top:0;bottom:0;margin:auto;}
/* middle title box */
	.middle-tit-box {margin:3rem 1.6rem 0 1.6rem;}
	.middle-tit-box h2 {font-size:2.4rem;line-height:3.6rem;font-weight:700;}
	.middle-tit-box + .chk-lists {padding-top:3rem;}
/* count check */
	.count-check .count-num {display:block;font-size:1.4rem;line-height:1.7rem;color:#343a40;}
/* main navigation */
	.main-nav {position:fixed;left:0;bottom:0;right:0;z-index:20000;background-color:#fff;}
	.main-nav.player-mode, .main-nav.sel-mode {background-color:#000;}
	.main-nav::before {content:'';position:absolute;left:0;top:0;right:0;height:1px;background-color:#f8f9fa;}
	.main-nav.player-mode::before, .main-nav.sel-mode::before {display:none;}
	.inner-progress-bar {
		position:absolute;left:0;height:0.2rem;
		background:#4545ff;
		background:-moz-linear-gradient(left,  #4545ff 0%, #992dff 100%);
		background:-webkit-linear-gradient(left,  #4545ff 0%,#992dff 100%);
		background:linear-gradient(to right,  #4545ff 0%,#992dff 100%);
		filter:progid:DXImageTransform.Microsoft.gradient( startColorstr='#4545ff', endColorstr='#992dff',GradientType=1 );
		-webkit-transition:width 0.3s;transition:width 0.3s;
	}
	.player-wrap .main-nav {bottom:constant(safe-area-inset-bottom);bottom:env(safe-area-inset-bottom);}
	.main-nav ul {
		display:-webkit-box;display:-moz-box;display:-ms-flexbox;display:-webkit-flex;display:flex;
		-webkit-box-align:center;-moz-box-align:center;-ms-box-align:center;-webkit-align-items:center;align-items:center;
		-webkit-box-pack:justify;-ms-flex-pack:justify;-webkit-justify-content:space-between;justify-content:space-between;
		position:relative;z-index:4;height:5.4rem;padding:0 1.6rem;text-align:center;
	}
	.main-nav li {position:relative;-webkit-flex:1;-moz-flex:1;flex:1;}
	.main-nav .thumb-outer {display:none;}
	.main-nav .thumb::before {width:auto;left:0;top:0;bottom:0;right:0;border:1px solid rgba(0,0,0,0.08);border-radius:100%;background:transparent;}
	.main-nav .item {position:relative;display:block;width:100%;height:5.4rem;text-align:left;}
	.main-nav .item:active {opacity:0.7;}
	.main-nav .item::before {
		content:'';position:absolute;left:50%;top:50%;
		display:block;width:3.2rem;height:3.2rem;margin:-1.6rem 0 0 -1.6rem;background:url('../images/set_ico_main_nav.png') no-repeat;background-size:auto 7.4rem;
	}
	.main-nav li:first-of-type .item::before {background-position:0 0;}
	.main-nav li:nth-of-type(2) .item::before {background-position:-4.2rem 0;}
	.main-nav li:nth-of-type(3) .item::before {background-position:-8.4rem 0;}
	.main-nav li:nth-of-type(4) .item::before {background-position:-12.6rem 0;}
	.main-nav li:nth-of-type(5) .item::before {background-position:-16.8rem 0;}
	.main-nav li.active:first-of-type .item::before {background-position:0 -4.2rem;}
	.main-nav li.active:nth-of-type(2) .item::before {background-position:-21rem -4.2rem;}
	.main-nav.player-mode li.active:nth-of-type(2) .item::before {background-position:-4.2rem -4.2rem;}
	.main-nav li.active:nth-of-type(3) .item::before {background-position:-8.4rem -4.2rem;}
	.main-nav li.active:nth-of-type(4) .item::before {background-position:-12.6rem -4.2rem;}
	.main-nav li.active:nth-of-type(5) .item::before {background-position:-16.8rem -4.2rem;}
	.main-nav li.profile .item::before {display:none;}
	.main-nav li.profile .thumb-outer {
		display:-webkit-box;display:-moz-box;display:-ms-flexbox;display:-webkit-flex;display:flex;
		-webkit-box-align:center;-moz-box-align:center;-ms-box-align:center;-webkit-align-items:center;align-items:center;
		-webkit-box-pack:justify;-ms-flex-pack:justify;-webkit-justify-content:space-between;justify-content:space-between;
		position:absolute;left:50%;top:50%;width:3.2rem;height:3.2rem;margin:-1.6rem 0 0 -1.6rem;
	}
	.main-nav li.profile .thumb-outer .thumb {
		position:relative;display:block;overflow:hidden;width:2.4rem;height:2.4rem;margin:0 auto;border-radius:2.4rem;background-repeat:no-repeat;background-position:50%;
		background-color:transparent;background-size:cover;
	}
	.main-nav li.profile.active .thumb-outer .thumb {overflow:visible;width:2.4rem;height:2.4rem;border-radius:2.4rem;}
	.main-nav li.profile.active .thumb-outer .thumb::after {content:'';position:absolute;left:-2.5px;top:-2.5px;right:-2.5px;bottom:-2.5px;z-index:5;border:1.5px solid #212529;border-radius:100%;}
	@-webkit-keyframes c-rotation {
		0%    {-webkit-transform:rotate(0deg);transform:rotate(0deg);}
		100% {-webkit-transform:rotate(360deg);transform:rotate(360deg);}
	}
	@keyframes c-rotation {
		0%    {-webkit-transform:rotate(0deg);transform:rotate(0deg);}
		100% {-webkit-transform:rotate(360deg);transform:rotate(360deg);}
	}
	.main-nav .inner-txt {position:absolute;overflow:hidden;clip:rect(0 0 0 0);width:0;height:0;border:0;padding:0;}
	.main-nav .flag-box {
		display:none;position:absolute;left:50%;top:-1.5rem;width:6.4rem;height:2rem;border-radius:2rem;text-align:center;font-size:0.9rem;color:#fff;letter-spacing:-0.4px;font-weight:500;
		-webkit-transform:translateX(-50%);transform:translateX(-50%);background:rgb(0,178,199);
		background:-moz-linear-gradient(top,  rgba(0,178,199,1) 0%, rgba(15,207,216,1) 100%);
		background:-webkit-linear-gradient(top,  rgba(0,178,199,1) 0%,rgba(15,207,216,1) 100%);
		background:linear-gradient(to bottom,  rgba(0,178,199,1) 0%,rgba(15,207,216,1) 100%);
		filter:progid:DXImageTransform.Microsoft.gradient( startColorstr='#00b2c7', endColorstr='#0fcfd8',GradientType=0 );
	}
	.main-nav .flag-box.active {display:block;}
	.main-nav .flag-box .loader {
		position:relative;display:inline-block;vertical-align:top;width:0.8rem;height:0.8rem;margin:0.6rem 0.3rem 0 0;border-radius:50%;text-indent:-999.9rem;
		background:#fff;
		background:-moz-linear-gradient(left, #fff 10%, rgba(255, 255, 255, 0) 70%);
		background:-webkit-linear-gradient(left, #fff 10%, rgba(255, 255, 255, 0) 70%);
		background:-o-linear-gradient(left, #fff 10%, rgba(255, 255, 255, 0) 70%);
		background:-ms-linear-gradient(left, #fff 10%, rgba(255, 255, 255, 0) 70%);
		background:linear-gradient(to right, #fff 10%, rgba(255, 255, 255, 0) 70%);
		-webkit-animation:c-rotation 1.4s infinite linear;animation:c-rotation 1.4s infinite linear;-webkit-transform:translateZ(0);-ms-transform:translateZ(0);transform:translateZ(0);
	}
	.main-nav .flag-box .loader::before {content:'';position:absolute;top:0;left:0;width:50%;height:50%;border-radius:100% 0 0 0;background:#fff;}
	.main-nav .flag-box .loader::after {content:'';position:absolute;top:0; left:0;bottom:0;right:0;width:75%;height:75%;border-radius:50%;margin:auto;background:#00b2c7;}
	.main-nav .flag-box .txt {display:inline-block;vertical-align:top;line-height:2rem;white-space:nowrap;}
	.nav-btn-box {position:relative;height:5rem;text-align:right;}
	.nav-btn-box::before {
		position:absolute;left:0;top:-3rem;z-index:1;content:'';width:100%;height:3rem;
		background:-moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 100%);
		background:-webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.5) 100%);
		background:linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.5) 100%);
		filter:progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
	}
	.nav-btn-box.no-grd::before {display:none;}
	.nav-btn-box button, .nav-btn-box a {float:right;overflow:hidden;width:auto;height:5rem;padding:0 1.6rem;line-height:5rem;color:#fff;font-weight:500;font-size:1.4rem;text-align:right;}
	.nav-btn-box button:active, .nav-btn-box a:active {opacity:0.3;}
	.nav-btn-box button:disabled, .nav-btn-box a.disabled {opacity:0.4;}
	.nav-btn-box button:disabled:active, .nav-btn-box a.disabled:active {opacity:0.4;}
/* flag */
	.s-flag {display:inline-block;height:1.7rem;margin-right:0.4rem;padding:0 0.4rem;border-radius:0.4rem;vertical-align:top;font-size:0.9rem;line-height:1.7rem;letter-spacing:-0.4px;color:#fff;font-weight:500;}
	.s-flag.type01 {background-color:rgba(0,0,0,0.5);}
	.s-flag.type02 {background-color:#ff414c;}
	.s-flag.type03 {background-color:#2445ff;}
	.thumb .flag, .thumb-wrap .flag {position:absolute;right:0;top:0;z-index:4;text-indent:0;min-width:3rem;height:1.9rem;border-top-right-radius:0.6rem;border-bottom-left-radius:0.6rem;padding:0 0.4rem;text-align:center;font-size:0.9rem;line-height:1.9rem;white-space:nowrap;font-weight:500;color:#fff;letter-spacing:-0.4px;}
	.thumb .flag.type01, .thumb-wrap .flag.type01 {background-color:rgba(0,0,0,0.5);}
	.thumb .flag.type02, .thumb-wrap .flag.type02 {background-color:#ff5455;}
	.thumb .flag.type03, .thumb-wrap .flag.type03 {background-color:#2445ff;}
	.best-list .thumb .flag, .best-list .thumb-wrap .flag {min-width:2.4rem;height:1.7rem;padding:0 0.2rem;line-height:1.7rem;}
	.fancy-flag {
		display:inline-block;height:2rem;border-radius:2rem;padding:0 1rem;vertical-align:top;line-height:2rem;font-size:1.1rem;font-weight:700;color:#FFF;
		background:-moz-linear-gradient(left,  #2445ff 0%, #7553ff 100%);
		background:-webkit-linear-gradient(left,  #2445ff 0%,#7553ff 100%);
		background:linear-gradient(to right,  #2445ff 0%,#7553ff 100%);
	}
/* coupon event box */
	.cp-evt-box {position:fixed;left:0;bottom:5.4rem;right:0;z-index:15000;height:5.6rem;}
	.no-main-nav .cp-evt-box {bottom:0;}
	.cp-evt-box .link-wrap {
		position:relative;z-index:15001;display:block;width:100%;height:5.6rem;padding-left:6.6rem;line-height:5.6rem;font-size:1.4rem;color:#fff;
		background:#4545ff;
		background:-moz-linear-gradient(left,  #4545ff 0%, #992dff 100%);
		background:-webkit-linear-gradient(left,  #4545ff 0%,#992dff 100%);
		background:linear-gradient(to right,  #4545ff 0%,#992dff 100%);
		filter:progid:DXImageTransform.Microsoft.gradient( startColorstr='#4545ff', endColorstr='#992dff',GradientType=1 );
	}
	.cp-evt-box .link-wrap::before {content:'';position:absolute;left:2rem;top:50%;width:3.8rem;height:3.8rem;margin-top:-1.9rem;background:url('../images/ico_coupon.png') no-repeat;background-size:3.8rem;}
	.cp-evt-box em {color:#ffec54;}
	.cp-evt-box .inner-closed-btn {position:absolute;right:0.8rem;top:50%;z-index:15002;overflow:hidden;width:3.6rem;height:3.6rem;margin-top:-1.8rem;text-indent:-999.9rem;background:url('../images/btn_delete_s.png') 50% 50% no-repeat;background-size:2rem;}
/* header- sub section */
	.sub-sect {position:relative;z-index:1;height:5.2rem;}
	.sub-sect h1 {overflow:hidden;height:5.2rem;margin:0 8rem;text-align:center;font-weight:700;font-size:1.6rem;line-height:5.2rem;text-overflow:ellipsis;white-space:nowrap;}
	.sub-sect h1.narrow-mode {margin:0 8.4rem;}
	.sub-sect .inline-truncate {display:inline-flex;max-width:100%;vertical-align:top;}
	.sub-sect .inline-truncate .flex-item {flex:1;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}
	.header .sub-sect h1.always {opacity:1;}
	.sub-sect h1 .ico {position:relative;overflow:hidden;display:inline-block;line-height:5.4rem;vertical-align:top;}
	.sub-sect h1 .ico.down {padding-right:2rem;}
	.sub-sect h1 .ico.down:active {opacity:0.3;}
	.sub-sect h1 .ico.down::after {content:'';position:absolute;right:0;top:50%;display:block;width:2rem;height:2rem;margin-top:-1rem;background:url('../images/ico_below_arrow.png') no-repeat;background-size:2rem;}
	.sub-sect h1.logo {opacity:1;overflow:hidden;text-indent:-999.9rem;background:url('../images/img_logo_vcoloring06.png') 50% 50% no-repeat;background-size:auto 2rem;}
	.sub-sect .l-align {margin-left:5.4rem;margin-right:1.6rem;text-align:left;}
	.sub-sect .active .ico.down::after {-webkit-transform:rotate(180deg);transform:rotate(180deg);}
	.sub-sect .l-side, .sub-sect .r-side {position:absolute;top:0;z-index:1;}
	.sub-sect .l-side {left:0;}
	.sub-sect .r-side {right:0;}
	.sub-sect .btn {display:block;overflow:hidden;width:5.4rem;height:5.2rem;text-indent:-999.9rem;background-position:50%;background-repeat:no-repeat;background-size:3.2rem;}
	.sub-sect .btn:active {opacity:0.3;}
	.sub-sect .btn-txt {display:block;overflow:hidden;height:5.2rem;padding:0 1.6rem;font-size:1.4rem;line-height:5.2rem;font-weight:500;}
	.sub-sect .btn-txt:active {opacity:0.3;}
	.sub-sect .btn:disabled {opacity:0.3;}
	.sub-sect .btn-txt:disabled {color:#b2b2b2;}
	.sub-sect .btn.back {background-image:url('../images/btn_back.png');}
	.sub-sect .btn.back-white {background-image:url('../images/btn_back_white.png');}
	.sub-sect .btn.sound {background-image:url('../images/btn_sound.png');}
	.sub-sect .btn.camera {background-image:url('../images/btn_camera.png');}
	.sub-sect .btn.closed {background-image:url('../images/btn_close.png');}
	.sub-sect .btn.histroy {background-image:url('../images/btn_my_history.png');}
	.sub-sect .btn.share {background-image:url('../images/btn_share.png');}
	.sub-sect .btn.setting {background-image:url('../images/btn_more_other.png');}
	.sub-sect .btn.mute {position:relative;background:none;-webkit-transition:transform 300ms;transition:transform 300ms;}
	.sub-sect .btn.mute::before {content:'';position:absolute;left:50%;top:50%;width:3.2rem;height:3.2rem;margin:-1.6rem 0 0 -1.6rem;background:url('../images/set_btn_mute02.png') no-repeat;background-position:-4.2rem 0;background-size:auto 3.2rem;}
	.sub-sect .btn.mute:active {-webkit-transform:scale(0.8);transform:scale(0.8);}
	.sub-sect .btn.mute.inactive::before {background-position:0 0;}
	.header .dropdown-box {display:none;position:absolute;left:50%;top:5.2rem;z-index:1;width:12.8rem;padding:0.8rem 0;margin-left:-6.4rem;box-shadow:0 0.8rem 2.4rem rgba(0,0,0,0.25);background-color:#fff;}
	.header .dropdown-box .frm-chk.st02[type='radio'] + label {height:4.4rem;padding-left:1rem;line-height:4.4rem;}
	.header .active + .dropdown-box {display:block;}
	.other-header .sub-sect .btn.back {background-image:url('../images/btn_back_white.png');}
	.other-header.reverse {background-color:#fff;}
	.other-header.reverse .sub-sect .btn.back {background-image:url('../images/btn_back.png');}
	.other-header.reverse .sub-sect h1 {opacity:1;}
	.sub-sect.flex-mode {
		display:-webkit-box;display:-moz-box;display:-ms-flexbox;display:-webkit-flex;display:flex;
		-webkit-box-pack:justify;-ms-flex-pack:justify;-webkit-justify-content:space-between;justify-content:space-between;
	}
	.sub-sect.flex-mode .l-side, .sub-sect.flex-mode .r-side {position:static;}
	.sub-sect.flex-mode  h1 {-moz-flex:1;-webkit-flex:1;flex:1;margin:0;}
/* checkbox &amp; radio list */
	.chk-rdo-list .frm-chk.st01[type='radio'] + label {padding:0 1.6rem;}
	.chk-rdo-list .frm-chk.st01[type='radio'] + label::before {right:1.6rem;}
	.chk-rdo-list li {margin-top:0.8rem;}
	.chk-rdo-list li:first-of-type {margin-top:0;}
	.chk-rdo-list .essential .s-head, .chk-rdo-list .essential .m-txt {color:#000;font-weight:700;}
/* button module */
	.btn-module {display:-webkit-box;display:-moz-box;display:-ms-flexbox;display:-webkit-flex;display:flex;padding:1.6rem 1.6rem 2.4rem 1.6rem;}
	.no-main-nav .btn-module {padding-bottom:3rem;}
	.sub-container .btn-module {padding-top:0;margin-top:4rem;padding-bottom:1rem;}
	.no-main-nav .sub-container .btn-module {padding-bottom:1rem;}
	.btn-module .l-side, .btn-module .r-side {-webkit-box-flex:1;ms-flex:1;flex:1;}
	.btn-module .btn-linker {border-bottom:1px solid #495057;margin-top:1rem;font-size:1.2rem;line-height:1.8rem;color:#495057;}
	.btn-module .btn-linker:active {opacity:0.3;}
	.btn-module .r-side {text-align:right;}
	.btn-module .btn-key {
		overflow:hidden;min-width:10rem;height:4.6rem;border-radius:4.6rem;
		text-align:center;color:#fff;line-height:4.6rem;
		background:rgb(0,178,199);
		background:-moz-linear-gradient(top,  rgba(0,178,199,1) 0%, rgba(15,207,216,1) 100%);
		background:-webkit-linear-gradient(top,  rgba(0,178,199,1) 0%,rgba(15,207,216,1) 100%);
		background:linear-gradient(to bottom,  rgba(0,178,199,1) 0%,rgba(15,207,216,1) 100%);
		filter:progid:DXImageTransform.Microsoft.gradient( startColorstr='#00b2c7', endColorstr='#0fcfd8',GradientType=0 );
	}
	.btn-module .btn-key:active {opacity:0.7;}
	.btn-module .btn-key span {display:inline-block;padding:0 2.1rem;vertical-align:top;line-height:4.6rem;font-size:1.5rem;font-weight:500;}
	.btn-module .btn-key .ico {display:block;overflow:hidden;text-indent:-999.9rem;background-position:50%;background-repeat:no-repeat;}
	.btn-module .btn-key .ico.del {background-image:url('../images/btn_delete.png');background-size:3.2rem;}
	.btn-module .btn-key.alt-bg {background:#000;}
	.btn-module .btn-key.disabled {background:#ced4da;}
	.btn-module .btn-key:disabled, .btn-module .btn-key.alt-bg:disabled {opacity:1;background:#ced4da;}
	.btn-module .btn-key:disabled .ico.del {opacity:0.7;}
/* ok module */
	.ok-module {margin-top:2rem;padding:0 5rem;text-align:center;}
	.flx-sti-footer .ok-module {padding-bottom:3rem;}
	.flx-sti-footer .ok-module.more-pb {padding-bottom:5rem;}
	.ok-module.wide-type {margin-top:2rem;padding-left:3rem;padding-right:3rem;}
	.ok-module.a-few-wide-type {margin-top:2rem;padding-left:4rem;padding-right:4rem;}
	.ok-module.full-wide-type {margin-top:0;padding-left:1.6rem;padding-right:1.6rem;}
	.full-w-mode .ok-module.full-wide-type {padding-bottom:2rem;}
	.ok-module.mid-wide-type {margin-top:3rem;padding-left:3rem;padding-right:3rem;}
	.ok-module.mid-wide-type button, .ok-module.mid-wide-type a {margin-top:1rem;}
	.ok-module.mid-wide-type button:first-of-type, .ok-module.mid-wide-type a:first-of-type {margin-top:0;}
	.ok-module.l-mg-t {margin-top:10rem;}
	.ok-module button, .ok-module a {display:block;overflow:hidden;width:100%;height:4.6rem;margin-top:1.2rem;line-height:4.6rem;text-align:center;font-size:1.5rem;color:#495057;}
	.ok-module button.other-st, .ok-module a.other-st {display:inline-block;width:auto;height:auto;margin-top:1.6rem;line-height:2rem;text-decoration:underline;color:#868e96;vertical-align:top;}
	.ok-module button:active, .ok-module a:active {opacity:0.7;}
	.ok-module button:first-of-type, .ok-module a:first-of-type {margin-top:0;}
	.ok-module button.sp, .ok-module a.sp {
		color:#fff;border-radius:4.6rem;font-weight:500;background:rgb(0,178,199);
		background:-moz-linear-gradient(top,  rgba(0,178,199,1) 0%, rgba(15,207,216,1) 100%);
		background:-webkit-linear-gradient(top,  rgba(0,178,199,1) 0%,rgba(15,207,216,1) 100%);
		background:linear-gradient(to bottom,  rgba(0,178,199,1) 0%,rgba(15,207,216,1) 100%);
		filter:progid:DXImageTransform.Microsoft.gradient( startColorstr='#00b2c7', endColorstr='#0fcfd8',GradientType=0 );
	}
	.ok-module button.sp:active, .ok-module a.sp:active {opacity:0.7;}
	.ok-module button.sp:disabled, .ok-module a.sp.disabled {background:#ced4da;}
	.ok-module button.sp:disabled:active, .ok-module a.sp.disabled:active {opacity:1;}
	.ok-module button.normal, .ok-module a.normal {border:1px solid #dfdfdf;border-radius:4.6rem;line-height:4.4rem;color:#868e96;background-color:#fff;}
	.ok-module.normal-st {padding:0 6.6rem;margin-top:3rem;}
	.ok-module.normal-st button, .ok-module.normal-st a {
		border:1px solid #ced4da;border-radius:4.6rem;margin-top:0;font-size:1.4rem;color:#000;background-color:#fff;
	}
	.ok-module.normal-st .ico.plus {display:inline-block;overflow:hidden;width:1.1rem;height:1.1rem;margin:1.7rem 0.6rem 0 0;text-indent:-999.9rem;vertical-align:top;background:url('../images/ico_plus.png') no-repeat;background-size:1.1rem;}
	.ok-module.other-st {margin-top:3rem;padding:0 1.6rem;}
	.ok-module.other-st button, .ok-module.other-st a {display:inline-block;width:auto;min-width:26rem;padding:0 2rem;vertical-align:top;}
	.footer-txt-box {text-align:center;}
	.footer-txt-box .g-txt {font-size:1.4rem;line-height:2rem;}
	.footer-txt-box .g-txt.white {color:#fff;}
	.footer-txt-box.other-st {text-align:left;padding:0 1.6rem;margin-bottom:1rem;}
	.footer-txt-box.gaping-space {margin-bottom:2rem;}
	.footer-txt-box.other-st .bul-txt {position:relative;padding-left:0.8rem;font-size:1.2rem;line-height:1.7rem;color:#868e96;}
	.footer-txt-box.other-st .bul-txt::before {position:absolute;left:0;top:0.4rem;content:'';width:0.4rem;height:0.4rem;border-radius:0.4rem;background-color:#ced4da;}
/* common float box */
	.cmm-float-box {position:fixed;left:0;right:0;bottom:3rem;z-index:100;}
/* multi lines button module */
	.multi-btn-module {padding:0 1.6rem;margin:3rem 0 5rem 0;}
	.multi-btn-module .g-btn {display:block;width:100%;height:4.6rem;margin-top:1rem;border:1px solid #495057;border-radius:4.6rem;line-height:4.4rem;font-weight:500;font-size:1.5rem;color:#495057;text-align:center;}
	.multi-btn-module .g-btn:first-child {margin-top:0;}
	.multi-btn-module .g-btn.other-st {border-color:#f8f9fa;color:#343a40;background-color:#f8f9fa;}
/* top header title */
	.top-head-tit {padding:1rem 1.6rem 0 1.6rem;}
	.top-head-tit.more-interval {padding-top:3rem;}
	.top-head-tit.more-interval + .device-wrap.smaller {margin-top:3rem;}
	.top-head-tit h1 .s-txt {display:block;margin-bottom:0.4rem;font-size:1.6rem;line-height:2.4rem;}
	.top-head-tit h1 strong {display:block;font-size:3rem;line-height:4.2rem;font-weight:700;}
	.top-head-tit h1 strong + .s-txt {margin:1rem 0 0 0;}
	.top-head-tit.other-st h1 strong {font-size:2.4rem;line-height:3.2rem;}
	.top-head-tit.other-st h1 .s-txt {margin:1.2rem 0 0 0;}
	.top-center-tit {position:relative;z-index:1;padding:1rem 1.6rem 0 1.6rem;text-align:center;}
	.top-center-tit h1 {font-size:2.4rem;line-height:3.2rem;color:#000;font-weight:700;}
	.top-center-tit .g-txt {margin-top:0.8rem;font-size:1.4rem;line-height:2rem;color:#495057;}
/* simple join information */
	.simple-join-information {margin:4rem 1.6rem 0 1.6rem;}
	.simple-join-information h2 {margin-bottom:1.6rem;font-size:1.5rem;line-height:1.9rem;color:#000;}
	.simple-join-information .dl-lists {
		border-radius:0.8rem;padding:2rem;background-color:#f8f9fa;
	}
	.simple-join-information .dl-lists dl {display:flex;justify-content:space-between;margin-top:1rem;}
	.simple-join-information .dl-lists dl:first-of-type {margin-top:0;}
	.simple-join-information .dl-lists dl dt {font-size:1.4rem;line-height:2rem;font-weight:500;color:#868e96;}
	.simple-join-information .dl-lists dl dd {font-size:1.4rem;line-height:2rem;font-weight:700;color:#000;}
	.simple-join-information .sol-tip-txt {position:relative;margin-top:1rem;padding-left:0.8rem;font-size:1.2rem;line-height:1.4rem;color:#868e96;}
	.simple-join-information .sol-tip-txt::before {content:'';position:absolute;left:0;top:0.5rem;width:0.4rem;height:0.4rem;border-radius:0.4rem;background-color:#ced4da;}
/* add sub text */
	.add-sub-txt {margin:3rem 1.6rem 0 1.6rem;}
	.add-sub-txt p {font-size:1.6rem;line-height:2.4rem;}
/* form box */
	.frm-box {padding:3rem 1.6rem 0 1.6rem;}
	.frm-box.dimmed {opacity:0.3;}
	.frm-box .el-dropdown {display:block;}
	.sol-tit-box.other-st + .frm-box {padding-top:0;margin-top:2.4rem;}
	.frm-box .row {position:relative;margin-top:0.8rem;}
	.frm-box.more-interval .row {margin-top:2rem;}
	.frm-box.more-interval .row:first-of-type {margin-top:0;}
	.frm-box .row + .other-row {margin-top:3rem;}
	.frm-box .row.other-space {margin-top:3rem;}
	.frm-box .row.other-space02,
	.frm-box .other-row.other-space02 {margin-top:1rem;}
	.frm-box .row:first-of-type {margin-top:0;}
	.frm-box .txt-line {display:block;font-size:1.2rem;line-height:1.8rem;font-weight:500;}
	.frm-box .txt-line em {color:#868e96;font-weight:400;}
	.frm-box .sim-tab-nav {margin:0.8rem 0 0 0;padding:0;}
	.frm-box h2.txt-line {padding-bottom:0.2rem;}
	.frm-box .inner-chk-rdo-box {margin-top:1rem;}
	.frm-box .inner-chk-rdo-box .frm-chk.st05[type='radio'] + label {margin-right:2.1rem;}
	.frm-box .inp-st {display:block;height:5.8rem;border-bottom:1px solid #dee2e6;font-size:2rem;color:#000;-webkit-transition:border-color 400ms;transition:border-color 400ms;}
	.frm-box .inp-st.s-size {font-size:1.5rem;}
	.frm-box .inp-st::-webkit-input-placeholder {color:#adb5bd;}
	.frm-box .inp-st:active, .frm-box .inp-st:focus, .frm-box .inp-st.done {border-color:#000;}
	.frm-box .inp-st.other-st {border:1px solid #adb5bd;border-radius:0.3rem;padding:0 0 0 1.5rem;font-size:1.5rem;line-height:5.8rem;color:#000;-webkit-transition:none;transition:none;}
	.frm-box .inp-st.other-st::-webkit-input-placeholder {color:#000;}
	.frm-box .inp-st.other-st.focus {border-color:#00b2c7;}
	.frm-box .other-row {position:relative;}
	.frm-box .other-row .timer {position:absolute;right:0;bottom:2.1rem;color:#00b2c7;font-size:1.2rem;line-height:1.6rem;}
	.frm-box .other-row .timer.btn-side {right:9.6rem;}
	.frm-box .ins-btn {position:absolute;right:0;bottom:1rem;overflow:hidden;min-width:8.6rem;height:3.6rem;padding:0 1rem;border-radius:0.4rem;line-height:3.6rem;text-align:center;color:#fff;font-size:1.2rem;background-color:#212529;}
	.frm-box .ins-btn.narrow-mode {min-width:8.6rem;font-size:1.2rem;}
	.frm-box .ins-btn.inactive {display:none;}
	.frm-box .ins-btn:disabled, .frm-box .ins-btn.sp:disabled {opacity:1;background:#ced4da;}
	.frm-box .ins-btn:active {opacity:0.7;}
	.frm-box .ins-btn.sp {
		background:rgb(0,178,199);
		background:-moz-linear-gradient(top,  rgba(0,178,199,1) 0%, rgba(15,207,216,1) 100%);
		background:-webkit-linear-gradient(top,  rgba(0,178,199,1) 0%,rgba(15,207,216,1) 100%);
		background:linear-gradient(to bottom,  rgba(0,178,199,1) 0%,rgba(15,207,216,1) 100%);
		filter:progid:DXImageTransform.Microsoft.gradient( startColorstr='#00b2c7', endColorstr='#0fcfd8',GradientType=0 );		
	}
	.frm-box .txt-btn {position:absolute;right:0;bottom:1rem;overflow:hidden;height:3.6rem;line-height:3.6rem;text-align:right;color:#00b2c7;font-weight:500;font-size:1.4rem;}
	.frm-box .txt-btn:disabled {color:rgba(0,0,0,0.3);}
	.frm-box .linker-btn-row {margin-top:1.6rem;}
	.frm-box .linker-btn-row button, .frm-box .linker-btn-row a {display:inline-block;padding-right:1.5rem;font-size:1.4rem;line-height:2.4rem;vertical-align:top;color:#495057;background:url('../images/ico_arrow.png') 100% 0 no-repeat;background-size:1.4rem 2rem;}
	.frm-box .linker-btn-row button:active, .frm-box .linker-btn-row a:active {opacity:0.3;}
	.frm-box .row .fixed-box {position:relative;}
	.frm-box .fixed-box .fixed-num {position:absolute;left:0;top:1.7rem;width:3.4rem;max-width:3.4rem;min-width:3.4rem;font-size:2rem;color:#000;}
	.frm-box .fixed-box .ltt {position:absolute;left:3.4rem;top:1.7rem;width:2.2rem;max-width:2.2rem;min-width:2.2rem;font-size:2rem;color:#000;text-align:center;}
	.frm-box .row .fixed-box .inp-st {padding-left:5.6rem;}
	.frm-box .row .fixed-box.reverse .inp-st {padding-left:4rem;}
	.frm-box .row .fixed-box.reverse .ins-img-btn {right:auto;left:0;}
	.frm-caption {padding-top:4rem;text-align:center;}
	.frm-caption h2 {font-size:1.4rem;line-height:2.1rem;color:#868e96;}
	.frm-caption + .frm-box {padding-top:3rem;}
	.pur-info-box + .frm-caption, .pur-info-box + .time-ctrl-box {padding-top:3.6rem;}
	.frm-box .g-txt {margin-top:0.8rem;font-size:1.2rem;line-height:1.7rem;color:#868e96;}
	.frm-box .ins-img-btn {position:absolute;right:0;bottom:1.3rem;overflow:hidden;width:3.2rem;height:3.2rem;text-indent:-999.9rem;background:url('../images/btn_contact.png') no-repeat;background-size:3.2rem;}
	.frm-box .ins-img-btn:active {opacity:0.3;}
	.frm-box .other-pos-row .inp-st {padding-left:4rem;}
	.frm-box .other-pos-row .ins-img-btn {right:auto;left:0;}
	.frm-box .ins-txt-btn {position:absolute;right:0;bottom:1.1rem;height:3.2rem;font-size:1.4rem;line-height:3.2rem;font-weight:500;color:#00b2c7;}
	.frm-box .ins-txt-btn:active {opacity:0.3;}
	.frm-box .status-msg-box {margin-top:0.8rem;}
	.frm-box .status-msg-box p {font-size:1.2rem;line-height:1.8rem;color:#fe2727;}
	.frm-box .tip-txt-box {margin:1rem 0 0 0;word-break:keep-all;}
	.frm-box .tip-txt-box p {position:relative;margin-top:0.6rem;padding-left:0.8rem;font-size:1.2rem;line-height:1.7rem;color:#868e96;}
	.frm-box .tip-txt-box p:first-of-type {margin-top:0;}
	.frm-box .tip-txt-box p::before {position:absolute;left:0;top:0.5rem;content:'';width:0.4rem;height:0.4rem;border-radius:0.4rem;background-color:#ced4da;}
	.frm-box .tip-txt-box .acc-txt {padding-left:0;color:#ff5455;}
	.frm-box .tip-txt-box .acc-txt::before {display:none;}
	.frm-box .col-row {position:relative;display:-webkit-box;display:-moz-box;display:-ms-flexbox;display:-webkit-flex;display:flex;margin-top:0.8rem;}
	.frm-box .col-row.other-space {margin-top:3rem;}
	.frm-box .col-row .field {margin-left:1.6rem;}
	.frm-box .col-row .field:first-of-type {margin-left:0;}
	.frm-box .col-row .field.w-fixed {width:10rem;}
	.frm-box .top-g-txt {margin-bottom:3.6rem;font-size:1.4rem;line-height:2.1rem;color:#868e96;word-break:keep-all;}
	.frm-box .txt-inp-st {outline:transparent;overflow-y:hidden;display:block;width:100%;padding-top:1.7rem;padding-bottom:1.9rem;border-bottom:1px solid #dee2e6;font-size:1.5rem;color:#000;-webkit-transition:border-color 
	400ms;transition:border-color 400ms;}
	.frm-box .txt-inp-st::-webkit-input-placeholder {color:#adb5bd;}
	.frm-box .txt-inp-st:active, .frm-box .txt-inp-st:focus, .frm-box .txt-inp-st.done {border-color:#000;}
/* setting list */
	.setting {padding:0.8rem 0 0 0;}
	.setting li {position:relative;}
	.setting-list {position:relative;padding-top:1.1rem;margin-top:1rem;}
	.setting-list::before {position:absolute;left:1.6rem;right:1.6rem;top:0;content:'';height:1px;background-color:#f1f3f5;}
	.setting-list:first-of-type, .setting-list.wide-type {padding-top:0;margin-top:0;}
	.setting-list:first-of-type::before, .setting-list.wide-type::before {display:none;}
	.setting-list button, .setting-list a, .setting-list .txt-wrap {position:relative;display:block;overflow:hidden;width:100%;height:4.8rem;padding:0 1.6rem;font-size:1.5rem;line-height:4.8rem;color:#181818;text-align:left;}
	.setting-list .r-side {position:absolute;right:1.6rem;top:50%;-webkit-transform:translateY(-50%);transform:translateY(-50%);}
	.setting-list .r-side em {display:inline-block;font-size:1.3rem;line-height:2rem;color:#868e96;vertical-align:middle;}
	.setting-list .linker-type {display:inline-block;width:auto;height:auto;padding:0;margin-left:2rem;line-height:2rem;vertical-align:middle;font-size:1.3rem;color:#00b2c7;}
	.setting-list button:disabled, .setting-list a.disabled {opacity:0.3;}
	.setting-list button:active, .setting-list a:active {background-color:rgba(0,0,0,0.1);}
	.setting-list button:disabled:active, .setting-list a.disabled:active {background-color:transparent;}
	.setting-list.wide-type button, .setting-list.wide-type a {height:6.2rem;font-size:1.5rem;line-height:6.2rem;}
	.setting-list .mark {
		display:inline-block;min-width:1.6rem;height:1.6rem;margin:1.6rem 0 0 0.5rem;border-radius:1.6rem;padding:0 0.3rem;text-align:center;font-size:1rem;line-height:1.6rem;color:#fff;vertical-align:top;
		background:rgb(0,178,199);
		background:-moz-linear-gradient(top,  rgba(0,178,199,1) 0%, rgba(15,207,216,1) 100%);
		background:-webkit-linear-gradient(top,  rgba(0,178,199,1) 0%,rgba(15,207,216,1) 100%);
		background:linear-gradient(to bottom,  rgba(0,178,199,1) 0%,rgba(15,207,216,1) 100%);
		filter:progid:DXImageTransform.Microsoft.gradient( startColorstr='#00b2c7', endColorstr='#0fcfd8',GradientType=0 );
	}
	.setting-list .acc-txt {display:inline-block;margin-left:0.4rem;vertical-align:top;line-height:5rem;font-size:1rem;color:#00b2c7;font-weight:500;}
	.setting-list .s-txt {position:absolute;right:1.6rem;top:1.6rem;font-size:1.3rem;line-height:1.8rem;color:#868e96;}
	.setting-list .upd-chk {position:absolute;right:1.6rem;top:50%;display:block;overflow:hidden;width:auto;height:auto;margin-top:-0.8rem;padding:0;font-size:1.3rem;line-height:1.6rem;text-align:left;color:#adb5bd;}
	.setting-list .upd-chk .ver {display:inline-block;margin-right:2rem;font-size:1.3rem;line-height:1.6rem;vertical-align:top;color:#868e96;}
	.setting-list a.upd-chk, .setting-list button.upd-chk {margin-top:-1.5rem;color:#00b2c7;line-height:3rem;}
	.setting-list .upd-chk .normal {color:#868e96;}
	.setting-list a.upd-chk.disabled, .setting-list button.upd-chk:disabled {opacity:1;}
	.setting-list a.upd-chk .ver, .setting-list button.upd-chk .ver {line-height:3rem;}
	.setting-list strong {font-weight:700;}
/* gallery list box (2 column base) */
	.gal-list-box {padding:0.8rem 1.6rem 1.6rem 1.6rem;}
	.gal-list-box em {color:#00b2c7;}
	.add-tab-fixed .gal-list-box {padding-top:0;}
	.ui-scr-overlay.search .gal-list-box {padding-top:1rem;}
	.gal-list-box li {position:relative;min-width:0;}
	.gal-list-box .inner-chk-box {position:absolute;left:0;top:0;z-index:10;}
	.gal-list-box .inner-touch-box {position:absolute;right:0.6rem;top:0.6rem;z-index:10;}
	.gal-list-box .btn-thumb-del {display:block;overflow:hidden;width:3.2rem;height:3.2rem;text-indent:-999.9rem;background:url('../images/btn_close_white.png') no-repeat;background-size:3.2rem;}
	.gal-list-box .linker-wrap .txt-line .m-tit {height:1.7rem;margin-top:0.6rem;line-height:1.7rem;}
	.gal-list-box .linker-wrap .txt-line .s-txt {height:1.4rem;margin-top:0.4rem;line-height:1.4rem;}
	.gal-list-box .linker-wrap .btn-preview {position:absolute;right:0.8rem;bottom:0.4rem;z-index:2;overflow:hidden;width:3.2rem;height:3.2rem;text-indent:-999.9rem;background:url('../images/btn_preview.png') no-repeat;background-size:3.2rem;}
	.gal-list-box .linker-wrap .btn-preview:active {opacity:0.3;}
	.gal-list-box .linker-wrap .pick {position:absolute;left:1.2rem;top:1.2rem;z-index:2;text-indent:0;}
	.gal-list-box .linker-wrap .auto-setting {position:absolute;left:1.2rem;top:1.2rem;z-index:2;}
	.gal-list-box .linker-wrap .simple-auto-setting {position:absolute;left:1.2rem;top:1.2rem;z-index:2;}
	.gal-list-box .linker-wrap.dimmed .h-txt.ico-stop {top:10.7rem;margin-top:0;}
	.gal-list-box .landscape .linker-wrap.dimmed .h-txt.ico-stop {top:3rem;margin-top:0;}
	.gal-list-box .linker-wrap .my-ucc {position:absolute;right:0.6rem;top:0.6rem;z-index:2;overflow:hidden;width:3.2rem;height:3.2rem;text-indent:-999.9rem;background:url('../images/ico_my_ucc.png') no-repeat;background-size:3.2rem;}
	.gal-list-box .linker-wrap .my-gift {position:absolute;right:0.6rem;top:0.6rem;z-index:2;overflow:hidden;width:3.2rem;height:3.2rem;text-indent:-999.9rem;background:url('../images/ico_my_gift.png') no-repeat;background-size:3.2rem;}
	.gal-list-box .linker-wrap .my-auto {position:absolute;right:0.6rem;top:0.6rem;z-index:2;overflow:hidden;width:3.2rem;height:3.2rem;text-indent:-999.9rem;background:url('../images/ico_my_auto.png') no-repeat;background-size:3.2rem;}
	.gal-list-box .linker-wrap .date {position:absolute;right:1rem;top:1rem;z-index:2;color:#fff;font-weight:700;font-size:1.3rem;line-height:1.8rem;}
	.gal-list-box .linker-wrap .btn-like {position:absolute;right:0.6rem;top:0.6rem;z-index:2;display:block;overflow:hidden;width:3.2rem;height:3.2rem;text-indent:-999.9rem;background:url('../images/set_btn_share.png') no-repeat;background-size:auto 3.2rem;}
	.gal-list-box .linker-wrap .btn-like {background-position:0 0;}
	.gal-list-box .linker-wrap .btn-like.active {background-position:-4.2rem 0;}
	.gal-list-box ul {display:grid;grid-template-columns:repeat(2, 1fr);gap:10px;}
	.gal-list-box.thumb-in-txt-mode .linker-wrap .thumb::before {
		position:absolute;left:0;top:0;content:'';width:100%;height:100%;border-radius:0.6rem;
		background:-moz-linear-gradient(top,  rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.1) 75%, rgba(0,0,0,0.2) 100%);
		background:-webkit-linear-gradient(top,  rgba(0,0,0,0.1) 0%,rgba(0,0,0,0.1) 75%,rgba(0,0,0,0.2) 100%);
		background:linear-gradient(to bottom,  rgba(0,0,0,0.1) 0%,rgba(0,0,0,0.1) 75%,rgba(0,0,0,0.2) 100%);
		filter:progid:DXImageTransform.Microsoft.gradient( startColorstr='#1a000000', endColorstr='#33000000',GradientType=0 );
	}
	.gal-list-box .card-stack-box .thumb {height:15.9rem;}
	.gal-list-box .set-inner-txt {display:flex;flex-direction:column;margin-top:1.8rem;padding-bottom:0.6rem;}
	.gal-list-box .set-inner-txt .m-tit {font-size:1.4rem;line-height:1.7rem;color:#212529;}
	.gal-list-box .set-inner-txt .s-txt {margin-top:0.4rem;font-size:1.2rem;line-height:1.4rem;color:#868e96;}
	.gal-list-box .set-inner-txt .m-tit, .gal-list-box .set-inner-txt .s-txt {overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}
	.gal-list-box li .sp-fixed-module {position:absolute;left:auto;top:10.8rem;right:1.1rem;bottom:auto;-webkit-transform:translateY(0);transform:translateY(0);-webkit-transition:none;transition:none;}
	.gal-list-box li .sp-fixed-module .auto-setting.reduction {-webkit-transition:none;transition:none;}
	.gal-list-box .ui-extra, .gal-list-box .ui-extra-only-btn {
		display:-webkit-box;display:-moz-box;display:-ms-flexbox;display:-webkit-flex;display:flex;
		-webkit-box-pack:center;-moz-box-pack:center;-ms-box-pack:center;-webkit-justify-content:center;justify-content:center;
		-webkit-box-align:center;-moz-box-align:center;-ms-box-align:center;align-items:center;-webkit-align-items:center;
		-webkit-box-orient:vertical;-moz-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;
		position:absolute;left:0;top:0;right:0;z-index:4;width:100%;height:24.9rem;color:#fff;
	}
	.gal-list-box .ui-extra p {font-size:1.2rem;line-height:1.4rem;}
	.gal-list-box .ui-extra .inner-del-btn {position:absolute;right:0.6rem;top:0.6rem;overflow:hidden;width:3.2rem;height:3.2rem;text-indent:-999.9rem;background:url('../images/btn_close_white.png') no-repeat;background-size:3.2rem;}
	.gal-list-box li:first-of-type .ui-extra, .gal-list-box li:nth-of-type(2) .ui-extra, .gal-list-box li:first-of-type .ui-extra-only-btn, .gal-list-box li:nth-of-type(2) .ui-extra-only-btn {top:0;}
	.gal-list-box .landscape .ui-extra, .gal-list-box .landscape .ui-extra-only-btn {height:9.6rem;}
	.gal-list-box .landscape .ui-extra p {margin-bottom:0.8rem;}
	.gal-list-box .landscape .ui-extra .inner-btn {position:static;margin:0 auto;-webkit-transform:translateX(0);transform:translateX(0);}
	.gal-list-box .ui-extra-only-btn .inner-txt {position:absolute;left:50%;bottom:3rem;display:block;min-width:11.6rem;margin-left:-5.8rem;height:3.2rem;padding:0 1.6rem;border-radius:3.2rem;line-height:3.2rem;text-align:center;font-weight:700;font-size:1.3rem;white-space:nowrap;color:#fff;background-color:rgba(0,0,0,0.7);}
	.gal-list-box .landscape .ui-extra-only-btn .inner-txt {position:static;margin:0 auto;}
	/* portrait type */
	.gal-list-box.portrait-type .linker-wrap .thumb img.thumb {object-fit:cover;}
	.gal-list-box.portrait-type.more-pd {padding-top:1rem;}
	.gal-list-box.portrait-type .linker-wrap .txt-line {padding-bottom:0.6rem;}
	.gal-list-box.portrait-type .linker-wrap .thumb {height:24.9rem;}
	.gal-list-box.portrait-type .landscape .linker-wrap .thumb {height:9.6rem;}
	/* list content box */
	.list-cont-box {background-color:#fff;padding-bottom:6rem;}
	.list-cont-box.fixed-case {padding-bottom:8.6rem;}
	/* pinterest mode */
	.gal-list-box.p-mode {padding-top:1rem;padding-left:1.6rem;padding-right:1.6rem;}
	.gal-list-box.p-mode .linker-wrap .thumb.obj-video {width:100%;height:24.9rem;}
	.gal-list-box.p-mode .landscape .linker-wrap .thumb.obj-video {height:9.6rem;}
	.gal-list-box.p-mode .linker-wrap .txt-line {padding-bottom:1.6rem;}
	.gal-list-box.p-mode .ui-pub-masonry {display:block;column-count:2;column-gap:10px;}
	.gal-list-box.p-mode .ui-pub-masonry li {break-inside:avoid-column;width:auto;padding:0;}
	.gal-list-box.p-mode .ui-masonry {
		display:-webkit-box;display:-moz-box;display:-ms-flexbox;display:-webkit-flex;display:flex;
		-webkit-box-orient:horizontal;-moz-box-orient:horizontal;-ms-flex-direction:row;flex-direction:row;
		place-content:stretch center;
		width:100%;
	}
	.gal-list-box.p-mode .ui-masonry > div:not(:first-child) {
		display:-webkit-box;display:-moz-box;display:-ms-flexbox;display:-webkit-flex;display:flex;
		-moz-box-orient:vertical;-webkit-box-orient:vertical;box-orient:vertical;-webkit-flex-direction:column;flex-direction:column;
		place-content:stretch flex-start;flex:1 1 0;
		width:0;margin-left: 1rem;
	}
/* preview */
	.preview-box {position:relative;padding:0.4rem 0 0 0;text-align:center;}
	.preview-box .inner-sol-txt {margin-top:1.7rem;font-size:1.4rem;line-height:2.1rem;color:#868e96;}
	.preview-box.alter-st {margin-top:-1rem;padding-top:0;}
	.preview-box h2 {margin-bottom:2rem;font-size:1.2rem;line-height:1.7rem;color:#868e96;}
	.preview-box h2.f-up-size {padding:1.2rem 0 0 0;margin-bottom:2.4rem;font-size:1.4rem;line-height:2.1rem;}
	.preview-box.alter-st h2 {margin-bottom:1.6rem;font-size:1.5rem;line-height:2.1rem;color:#000;}
	.preview-box .mute {position:absolute;right:1.2rem;top:0.1rem;overflow:hidden;width:3.2rem;height:3.2rem;text-indent:-999.9rem;background:url('../images/set_btn_mute02.png') no-repeat;background-position:-4.2rem 0;background-size:auto 3.2rem;-webkit-transition:transform 300ms;transition:transform 300ms;}
	.preview-box .mute:active {-webkit-transform:scale(0.8);transform:scale(0.8);}
	.preview-box .mute.inactive {background-position:0 0;}
	.device-wrap {position:relative;width:24.6rem;height:52rem;margin:0 auto;}
	.device-wrap::before {content:'';position:absolute;left:0;top:0;z-index:2;width:24.6rem;height:52rem;background:url('../images/bg_device.png') no-repeat;background-size:24.6rem 52rem;}
	.device-wrap .device {
		display:-webkit-box;display:-moz-box;display:-ms-flexbox;display:-webkit-flex;display:flex;
		-webkit-box-pack:center;-moz-box-pack:center;-ms-box-pack:center;-webkit-justify-content:center;justify-content:center;
		-webkit-box-align:center;-moz-box-align:center;-ms-box-align:center;align-items:center;-webkit-align-items:center;
		position:absolute;left:50%;top:1.2rem;z-index:1;overflow:hidden;width:23.2rem;margin-left:-11.6rem;height:49.1rem;
	}
	.device-wrap .device video {width:100%;height:100%;object-fit:cover;}
	.device-wrap.smaller {width:19.2rem;height:40.6rem;margin-top:1.6rem;}
	.device-wrap.smaller::before {width:19.2rem;height:40.6rem;background-size:19.2rem 40.6rem;}
	.device-wrap.smaller .device {top:1rem;width:18.2rem;margin-left:-9.1rem;height:38.3rem;}
	.alter-st .device-wrap.x-smaller {margin-top:0;}
	.device-wrap.x-smaller {width:16.8rem;height:35.5rem;margin-top:1.6rem;}
	.device-wrap.x-smaller::before {width:16.8rem;height:35.5rem;background-size:16.8rem 35.5rem;}
	.device-wrap.x-smaller .device {top:0.9rem;width:15.8rem;margin-left:-7.9rem;height:33.5rem;}
	.ico-status {position:absolute;left:50%;z-index:3;width:7.9rem;height:2.4rem;margin:0 0 0 -4rem;border-radius:2.4rem;text-align:center;background-color:rgba(0,0,0,0.7);}
	.ico-status .inner-txt {display:inline-block;overflow:hidden;height:2.4rem;padding-left:1.6rem;line-height:2.4rem;font-weight:500;font-size:0.9rem;color:#fff;vertical-align:top;background:url('../images/ico_loading_error.png') 0 50% no-repeat;background-size:1.2rem;}
	.device-wrap.smaller .ico-status {top:24.1rem;}
	.device-wrap.x-smaller .ico-status {top:20.7rem;}
	/* landscape */
	.device-wrap.landscape::before {background-image:url('../images/bg_device_landscape.png');}
	.device-wrap.landscape .device {overflow:hidden;top:14.5%;z-index:3;height:53.4%;}
	.device-wrap.landscape .device video {position:absolute;left:50%;top:50%;display:block;width:100%;height:auto;object-fit:contain;-webkit-transform:translate(-50%, -50%);transform:translate(-50%, -50%);}
/* define of text information  */
	.def-txt-info {padding:0 1.6rem;margin:1.6rem 0 3rem 0;text-align:left;}
	.def-txt-info dt {font-size:1.8rem;line-height:2.5rem;font-weight:500;}
	.def-txt-info dd {margin-top:0.4rem;font-size:1.2rem;line-height:1.8rem;color:#868e96;}
	.def-txt-info-wrap {padding-top:2rem;margin-bottom:3rem;}
	.def-txt-info-wrap + .frm-box {padding-top:0;}
	.def-txt-info-wrap .def-txt-info {margin:0;}
/* simple tab navigation */
	.sim-tab-nav {padding:0 1.6rem;margin:3rem 0 3rem 0;}
	.sim-tab-nav h2 {margin-bottom:1rem;font-size:1.2rem;line-height:1.8rem;font-weight:500;}
	.sim-tab-nav ul {display:-webkit-box;display:-moz-box;display:-ms-flexbox;display:-webkit-flex;display:flex;}
	.sim-tab-nav ul li {-webkit-box-flex:1;ms-flex:1;flex:1;margin-left:-1px;}
	.sim-tab-nav ul li:first-of-type {margin-left:0;}
	.sim-tab-nav button, .sim-tab-nav a {display:block;width:100%;height:4.6rem;border:1px solid #dee2e6;font-weight:500;line-height:4.4rem;font-size:1.6rem;color:#adb5bd;text-align:center;}
	.sim-tab-nav + .frm-box {padding-top:0;}
	.sim-tab-nav .active button, .sim-tab-nav .active a {position:relative;z-index:1;border-color:#000;color:#000;}
/* base tab navigation */
	.base-tab-nav {position:relative;height:5.2rem;margin-top:1rem;}
	.base-tab-nav ul::before {content:'';position:absolute;left:0;bottom:0;right:0;z-index:0;height:1px;background-color:#e9ecef;}
	.base-tab-nav ul {position:absolute;left:0;right:0;z-index:2;display:-webkit-box;display:-moz-box;display:-ms-flexbox;display:-webkit-flex;display:flex;height:5.2rem;padding:0 1.6rem;background-color:#fff;}
	.base-tab-nav ul li {position:relative;z-index:1;margin-left:2.4rem;}
	.base-tab-nav ul li:first-of-type {margin-left:0;}
	.base-tab-nav ul button, .base-tab-nav ul a {display:block;height:5.2rem;border-bottom:2px solid Transparent;line-height:4.8rem;font-size:1.6rem;color:rgba(0,0,0,0.3);font-weight:500;}
	.base-tab-nav .active button, .base-tab-nav .active a {border-color:#000;color:#000;}
	.base-tab-nav + .frm-box {padding-top:2.2rem;}
	.base-tab-nav.fixed-nav {margin-top:0;}
	.base-tab-nav.fixed-nav ul {position:fixed;bottom:0;top:5.2rem;z-index:10;}
/* multi message box */
	.multi-msg-box {
		display:-webkit-box;display:-moz-box;display:-ms-flexbox;display:-webkit-flex;display:flex;
		-webkit-box-pack:center;-moz-box-pack:center;-ms-box-pack:center;-webkit-justify-content:center;justify-content:center;
		-webkit-box-align:center;-moz-box-align:center;-ms-box-align:center;-webkit-align-items:center;align-items:center;
		-webkit-box-orient:vertical;-moz-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;
		height:100%;
	}
	.multi-msg-box.sp-type {height:calc(100% - 5.4rem);padding-top:0.8rem;}
	.multi-msg-box.vh-type01 {height:calc(100vh - 19rem);}
	@supports (height: 100dvh) {
		.multi-msg-box.vh-type01 {
			height: calc(100dvh - 19rem);
		}
	}	
	.multi-msg-box.w-tabnav-type {height:calc(100vh - 32rem);}
	@supports (height: 100dvh) {
		.multi-msg-box.w-tabnav-type {
			height:calc(100dvh - 32rem);
		}
	}	
	.sol-tit-box.etc-st + .multi-msg-box {height:calc(100vh - 23.2rem);}
	@supports (height: 100dvh) {
		.sol-tit-box.etc-st + .multi-msg-box {
			height:calc(100dvh - 23.2rem);
		}
	}	

	.multi-msg-box .ok-module {margin:2.4rem 0 0 0;padding:0 6.5rem;width:100%;}
	.multi-msg-box .ok-module button {width:100%;}
	.multi-msg-box .g-txt {width:100%;padding:10rem 0;text-align:center;font-size:1.4rem;line-height:2.1rem;color:#868e96;}
	.multi-msg-box strong {font-weight:700;}
	/* message tag list box */
	.msg-tag-list-box {padding:2.4rem 0 4rem 0;text-align:center;}
	.msg-tag-list-box h1 {margin-bottom:1rem;font-size:2.4rem;line-height:3.2rem;font-weight:700;}
	.msg-tag-list-box .msg-txt {margin-bottom:2rem;font-size:1.4rem;line-height:2.1rem;color:#868e96;}
	.msg-tag-list-box .tag-block-list li {margin-top:0.8rem;}
	.msg-tag-list-box .tag-block-list li:first-of-type {margin-top:0;}
	.msg-tag-list-box .tag-block-list button, .msg-tag-list-box .tag-block-list a {display:inline-block;overflow:hidden;height:4rem;border-radius:4rem;padding:0 1.5rem 0 1.5rem;line-height:4rem;font-size:1.4rem;vertical-align:top;background-color:#f8f9fa;}
	.msg-tag-list-box .tag-block-list button:active, .msg-tag-list-box .tag-block-list a:active {opacity:0.3;}
	.multi-msg-box .msg-tag-list-box {padding:0;}
	.msg-tag-list-box .sub-link-box {margin-top:3.2rem;text-align:center;}
	.msg-tag-list-box .sub-link-box a, .msg-tag-list-box .sub-link-box button {display:inline-block;color:#000;vertical-align:top;font-size:1.4rem;border-bottom:1px solid #000;background-color:transparent;}
/* unnoticed button module */
	.un-btn-module {margin-top:3rem;padding:0 1.6rem 2.6rem 1.6rem;}
	.un-btn-module .g-txt {margin-top:1.2rem;font-size:1.2rem;line-height:1.7rem;color:#868e96;}
	.un-btn-module .g-txt.add-interval {margin-top:3rem;}
	.un-btn-module .btn-acc-box {margin-top:1.2rem;text-align:center;}
	.un-btn-module .btn-acc-box a, .un-btn-module .btn-acc-box button {display:block;width:100%;height:4.6rem;border-radius:4.6rem;text-align:center;line-height:4.6rem;font-size:1.5rem;font-weight:500;color:#343a40;background-color:#f8f9fa;}
	.un-btn-module .g-btn {position:relative;display:block;width:100%;height:5.8rem;padding:0 10rem 0 2rem;margin-top:1rem;border:1px solid #dee2e6;border-radius:0.3rem;text-align:center;}
	.un-btn-module .g-btn:first-of-type {margin-top:0;}
	.un-btn-module .g-btn .tit {display:block;overflow:hidden;height:5.6rem;line-height:5.6rem;font-size:1.5rem;font-weight:700;text-overflow:ellipsis;white-space:nowrap;text-align:left;}
	.un-btn-module .g-btn .status {position:absolute;right:2rem;;top:50%;margin-top:-1.1rem;font-size:1.5rem;line-height:2.2rem;text-align:right;}
	.un-btn-module .g-btn.dimmed .status {color:#adb5bd;}
/* purchase information box */
	.pur-info-box {margin:2rem 1.6rem 0 1.6rem;padding:1.9rem 2rem 1.5rem 1.9rem;border:1px solid #868e96;border-radius:0.3rem;}
	.pur-info-box dl {position:relative;}
	.pur-info-box .top-dt-box {padding:0 10.4rem 0.4rem 0;}
	.pur-info-box .top-dt-box dd {min-height:9rem;}
	.pur-info-box .top-dt-box .row {margin-top:0.4rem;}
	.pur-info-box .top-dt-box .row:first-of-type {margin-top:0;}
	.pur-info-box .top-dt-box dt {position:absolute;right:0;top:0;width:9rem;height:9rem;}
	.pur-info-box .top-dt-box h2 {font-size:1.6rem;line-height:2.4rem;font-weight:700;color:#000;}
	.pur-info-box .top-dt-box p {font-size:1.2rem;line-height:1.8rem;}
	.pur-info-box .top-dt-box .m-txt {color:#000;}
	.pur-info-box .top-dt-box .s-txt {color:#868e96;}
	.pur-info-box .top-dt-box .s-txt em {color:#ff5555;}
	.pur-info-box .top-dt-box dt .thumb {height:100%;background-position:50% 50%;}
	.pur-info-box .bt-dt-box {margin-top:1.6rem;padding-top:1.2rem;border-top:1px solid #dee2e6;font-size:1.4rem;line-height:2rem;color:#000;}
	.pur-info-box .bt-dt-box dt {position:absolute;left:0;top:1.2rem;}
	.pur-info-box .bt-dt-box dd {text-align:right;font-weight:500;}
	.pur-info-box.simple-mode {border:none;padding:2rem;background-color:#f8f9fa;}
	.pur-info-box.simple-mode .top-dt-box {padding:0 10.8rem 0 0;}
	.pur-info-box.simple-mode .top-dt-box h2 {padding-top:0.1rem;line-height:2.2rem;}
	.pur-info-box.simple-mode .top-dt-box .s-txt {display:block;margin-top:0.4rem;font-size:1.2rem;line-height:1.8rem;color:#495057;}
	.pur-info-box.simple-mode .top-dt-box .s-date {display:block;margin-top:0.4rem;font-size:1.4rem;line-height:1.9rem;}
	/* history */
	.pur-info-history {margin-top:2rem;}
	.pur-info-history .pur-info-box.simple-mode {position:relative;margin-top:1rem;}
	.pur-info-history .pur-info-box.simple-mode:first-of-type {margin-top:0;}
	.pur-info-history .pur-info-box.simple-mode .top-dt-box {min-height:9rem;padding-bottom:2.3rem;}
	.pur-info-history .pur-info-box.simple-mode .top-dt-box dd {min-height:1px;}
	.pur-info-history .pur-info-box.simple-mode .top-dt-box .s-date {position:absolute;left:0;bottom:0;font-size:1.2rem;line-height:1.7rem;}
	.pur-info-history .pur-info-box.simple-mode.dimmed {opacity:0.3;}
	.pur-info-box.simple-mode.dimmed .top-dt-box dt .thumb {filter:grayscale(100%);}
/* payment detail box */
	.pay-dt-box {padding:3rem 1.6rem;margin-top:3rem;background-color:#f8f9fa;}
	.pay-dt-box .inner-box {padding:2rem;border-radius:0.8rem;background-color:#FFF;}
	.pay-dt-box h2 {margin-bottom:2rem;font-weight:500;font-size:1.2rem;line-height:1.4rem;}
	.pay-dt-box .specification {padding-bottom:2rem;border-bottom:1px solid #dee2e6;}
	.pay-dt-box .row {position:relative;margin-top:2rem;line-height:2rem;}
	.pay-dt-box .row:first-of-type {margin-top:0;}
	.pay-dt-box .row h3 {position:absolute;left:0;top:0;font-size:1.4rem;line-height:2rem;color:#000;font-weight:700;}
	.pay-dt-box .row h3 em {display:inline-block;margin-left:0.2rem;vertical-align:middle;font-size:1.2rem;line-height:1.7rem;color:#000;}
	.pay-dt-box .row .won {display:block;text-align:right;font-size:1.4rem;line-height:2rem;color:#000;font-weight:700;}
	.pay-dt-box .row .won .light {font-weight:400;}
	.pay-dt-box .row .won.disabled {opacity:1;color:#000;}
	.pay-dt-box .row .won.acc {color:#000;}
	.pay-dt-box .sub-row {opacity:0;display:flex;align-items:center;justify-content:space-between;height:0;overflow:hidden;padding-left:1rem;font-size:1.4rem;line-height:1.7rem;color:#868e96;transition:all 300ms;}
	.pay-dt-box .sub-row.active {opacity:1;margin-top:1rem;overflow:visible;height:1.7rem;}
	.pay-dt-box .btn-block {position:relative;display:block;width:100%;margin-top:1rem;margin-bottom:0.4rem;padding-left:1.6rem;border:1px solid #868e96;border-radius:0.3rem;height:5.2rem;line-height:5rem;font-weight:700;font-size:1.4rem;color:#000;}
	.pay-dt-box .btn-block::after {content:'';position:absolute;top:50%;right:1.5rem;width:2rem;height:2rem;margin-top:-1rem;background:url('../images/ico_arrow02.png') no-repeat;background-size:2rem;}
	.pay-dt-box .btn-block:active {background-color:rgba(0,0,0,0.1);}
	.pay-dt-box .btn-block:disabled, .pay-dt-box .btn-block.disabled {border-color:#dee2e6;font-weight:400;color:#adb5bd;}
	.pay-dt-box .btn-block:disabled::after, .pay-dt-box .btn-block.disabled::after {background-image:url('../images/ico_arrow02_off.png');}
	.pay-dt-box .btn-block:disabled:active, .pay-dt-box .btn-block.disabled:active {background-color:transparent;}
	.pay-dt-box .total {position:relative;margin-top:2rem;}
	.pay-dt-box .total:only-child {margin-top:0;}
	.pay-dt-box .total h4 {position:absolute;left:0;top:0.4rem;font-size:1.4rem;line-height:1.7rem;color:#000;font-weight:700;}
	.pay-dt-box .total h4 em {display:inline-block;margin-left:0.2rem;vertical-align:top;font-size:1.4rem;line-height:1.7rem;color:#000;}
	.pay-dt-box .total .won {display:block;text-align:right;font-size:2rem;line-height:2.4rem;font-weight:700;}
/* coupon detail box */
	.cp-dt-box {margin:3rem 1.6rem 0 1.6rem;}
	.cp-dt-box .inner-tit-box {position:relative;line-height:1.8rem;}
	.cp-dt-box .inner-tit-box h2 {font-weight:500;font-size:1.2rem;line-height:1.8rem;}
	.cp-dt-box .inner-tit-box h2 em {margin-left:0.2rem;font-weight:400;color:#868e96;}
	.cp-dt-box .inner-tit-box .acc {position:absolute;right:0;top:0;font-size:1.2rem;line-height:1.8rem;font-weight:500;color:#ff5555;}
	.cp-dt-box .btn-block {position:relative;display:block;width:100%;margin-top:1rem;padding-left:1.6rem;border:1px solid #868e96;border-radius:0.3rem;height:5.2rem;line-height:5rem;font-weight:700;font-size:1.4rem;color:#000;}
	.cp-dt-box .btn-block::after {content:'';position:absolute;top:50%;right:1.5rem;width:2rem;height:2rem;margin-top:-1rem;background:url('../images/ico_arrow02.png') no-repeat;background-size:2rem;}
	.cp-dt-box .btn-block:active {background-color:rgba(0,0,0,0.1);}
	.cp-dt-box .btn-block:disabled, .pay-dt-box .btn-block.disabled {border-color:#dee2e6;font-weight:400;color:#adb5bd;}
	.cp-dt-box .btn-block:disabled::after, .pay-dt-box .btn-block.disabled::after {background-image:url('../images/ico_arrow02_off.png');}
	.cp-dt-box .btn-block:disabled:active, .pay-dt-box .btn-block.disabled:active {background-color:transparent;}
/* general information box */
	.g-info-box {position:relative;border:1px solid #868e96;padding:1.6rem 2rem;margin:2rem 1.6rem 0 1.6rem;border-radius:0.3rem;text-align:right;font-size:1.4rem;line-height:2rem;color:#000;}
	.g-info-box h3 {position:absolute;left:2rem;top:1.6rem;}
	.g-info-box p {font-weight:500;}
	.g-info-box + .pur-info-box {margin-top:1rem;}
	.g-info-box.gray-mode {border:none;margin-top:1rem;padding:2rem;font-size:1.5rem;line-height:1.8rem;background-color:#f8f9fa;}
	.g-info-box.gray-mode h3 {top:2rem;}
	.g-info-box.gray-mode p {font-weight:700;}
/* special box */
	.sp-box {
		position:relative;height:8rem;padding-right:12.8rem;background:rgb(165,130,238);
		background:-moz-linear-gradient(left,  rgba(50,26,186,1) 0%, rgba(232,49,124,1) 100%);
		background:-webkit-linear-gradient(left,  rgba(50,26,186,1) 0%,rgba(232,49,124,1) 100%);
		background:linear-gradient(to right,  rgba(50,26,186,1) 0%,rgba(232,49,124,1) 100%);
		filter:progid:DXImageTransform.Microsoft.gradient( startColorstr='#321aba', endColorstr='#e8317c',GradientType=1 );
	}
	.sp-box p {padding:2.2rem 0 0 2rem;font-size:1.2rem;line-height:1.8rem;font-weight:700;color:#fff;}
	.sp-box button, .sp-box a {position:absolute;right:2rem;top:50%;overflow:hidden;display:block;height:3rem;padding:0 1rem;border-radius:0.6rem;margin-top:-1.5rem;font-size:1.1rem;color:#fff;font-weight:500;line-height:3rem;white-space:nowrap;text-align:center;background-color:rgba(112,37,129,0.2);}
	.sp-box button:active, .sp-box a:active {opacity:0.7;}
/* simple login box */
	.simple-login-box {margin-top:10rem;padding:0 1.6rem;text-align:center;}
	.simple-login-box .logo-box {margin-bottom:5rem;}
	.simple-login-box .logo-box img {display:block;width:7.6rem;margin:0 auto;}
	.simple-login-box .btn-type, .simple-share-box .btn-type {display:block;height:4.6rem;border:1px solid #ced4da;margin-top:1rem;border-radius:4.6rem;width:100%;text-align:center;}
	.simple-login-box .btn-type:first-of-type, .simple-share-box .btn-type:first-of-type {margin-top:0;}
	.simple-login-box .btn-type span, .simple-share-box .btn-type span {position:relative;display:inline-block;padding-left:2.4rem;line-height:4.4rem;font-size:1.5rem;font-weight:500;vertical-align:top;}
	.simple-login-box .btn-type span::before, .simple-share-box .btn-type span::before {content:'';position:absolute;left:0;top:50%;width:1.8rem;height:1.8rem;margin-top:-0.9rem;background-repeat:no-repeat;background-size:1.8rem;}
	.simple-login-box .txt-linker {display:inline-block;margin-top:1.6rem;font-size:1.4rem;line-height:2rem;color:#868e96;text-decoration:underline;vertical-align:top;}
	.simple-login-box .btn-type.mobile {color:#495057;background-color:#fff;}
	.simple-login-box .btn-type.mobile span::before {background-image:url('../images/img_login_mobile.png');}
	.simple-login-box .btn-type.t {border-color:#3617ce;color:#fff;background-color:#3617ce;}
	.simple-login-box .btn-type.t span::before {background-image:url('../images/img_login_social_t.png');}
	.simple-login-box .btn-type.kakao, .simple-share-box .btn-type.kakao {border-color:#fae102;color:#391b1b;background-color:#fae102;}
	.simple-login-box .btn-type.kakao span::before, .simple-share-box .btn-type.kakao span::before {background-image:url('../images/img_login_social_kakao.png');}
	.simple-share-box {margin-top:6rem;margin-bottom:5rem;padding:0 1.6rem;}
	.simple-share-box h2 {font-size:2rem;line-height:2rem;font-weight:700;}
	.simple-share-box .g-txt {margin-top:1rem;margin-bottom:2rem;font-size:1.4rem;line-height:2.1rem;color:#868e96;}
/* id list */
	.id-list li {position:relative;height:5.8rem;margin-top:1rem;padding-left:6.4rem;}
	.id-list .ico {position:absolute;left:1.6rem;top:50%;overflow:hidden;width:2.8rem;height:2.8rem;margin-top:-1.4rem;text-indent:-999.9rem;background-repeat:no-repeat;background-size:2.8rem;}
	.id-list .ico.t {background-image:url('../images/img_social_t.png');}
	.id-list .ico.kakao {background-image:url('../images/img_social_kakao.png');}
	.id-list .ico.mobile {background-image:url('../images/img_social_mobile.png');}
	.id-list .txt-info {
		display:-webkit-box;display:-moz-box;display:-ms-flexbox;display:-webkit-flex;display:flex;
		-webkit-box-pack:center;-moz-box-pack:center;-ms-box-pack:center;justify-content:center;-webkit-justify-content:center;
		-moz-box-orient:vertical;-webkit-box-orient:vertical;box-orient:vertical;-webkit-flex-direction:column;flex-direction:column;height:5.8rem;text-align:left;
	}
	.id-list .txt-info .txt, .id-list .txt-info .log {display:block;}
	.id-list .txt-info .txt {font-size:1.5rem;line-height:1.8rem;color:#000;}
	.id-list .txt-info .log {margin-top:0.4rem;font-size:1.2rem;line-height:1.8rem;color:#868e96;}
	.id-list .btn-txt-type {position:absolute;right:0;top:50%;-webkit-transform:translateY(-50%);transform:translateY(-50%);padding:0 1.6rem;font-size:1.3rem;line-height:4rem;color:#00b2c7;}
	.id-list .btn-txt-type:active {background-color:rgba(0,0,0,0.1);}
/* catch eye box */
	.catch-eye-wrap {margin:3rem 1.6rem 0 1.6rem;}
	.catch-eye-box {display:block;width:100%;padding:1.4rem 1.6rem 1.5rem 1.6rem;border:1px solid #868e96;border-radius:0.3rem;text-align:left;}
	.catch-eye-box .tit-block {display:block;font-size:1.4rem;line-height:2rem;font-weight:500;}
	.catch-eye-box .txt-block {position:relative;display:block;margin-top:0.4rem;font-size:1.2rem;line-height:1.7rem;}
	.catch-eye-box .txt-block .link-ico {display:inline-block;padding:0.1rem 1.2rem 0 0;vertical-align:top;font-size:1.2rem;line-height:1.7rem;color:#000;background:url('../images/ico_arrow04.png') 100% 50% no-repeat;background-size:1.2rem;}
	.catch-eye-box .txt-block .l-side {display:block;}
	.catch-eye-box .txt-block .r-side {position:absolute;right:0;top:0;font-size:1.2rem;line-height:1.7rem;}
	.catch-eye-box .txt-block .r-side del {color:#868e96;}
	.catch-eye-box .txt-block .r-side strong {margin-left:0.4rem;font-weight:700;}
/* main common tab navigation */
	.m-cmn-tab-outer {position:relative;height:5.2rem;border-bottom:1px solid #e9ecef;}
	.m-cmn-tab-nav {
		-ms-overflow-style:none;scrollbar-width:none;-webkit-overflow-scrolling:touch;
		position:absolute;left:0;top:0;right:0;overflow:auto;overflow-y:hidden;height:5.2rem;padding-left:1.6rem;
	}
	.m-cmn-tab-nav::-webkit-scrollbar {display:none;}
	.m-cmn-tab-nav.fixed-nav {position:fixed;z-index:1000;background-color:#fff;}
  .m-cmn-tab-nav ul {position:relative;z-index:2;display:-webkit-box;display:-moz-box;display:-ms-flexbox;display:-webkit-flex;display:flex;}
  .m-cmn-tab-nav li {flex-shrink:0;margin-left:2.4rem;}
	.m-cmn-tab-nav li:first-of-type {margin-left:0;}
  .m-cmn-tab-nav li:last-of-type {padding-right:1.6rem;}
	.m-cmn-tab-nav .d-linker-evt {
    display:-webkit-box;display:-moz-box;display:-ms-flexbox;display:-webkit-flex;display:flex;
    -webkit-box-align:center;-moz-box-align:center;-ms-box-align:center;box-align:center;-webkit-align-items:center;align-items:center;
    position:absolute;right:2rem;top:0;bottom:0;padding-left:2.2rem;font-size:1.5rem;line-height:2rem;color:#343a40;font-weight:400;
    background:url('../images/ico_main_tab_evt.png') 0 50% no-repeat;background-size:2rem;
  }
  .m-cmn-tab-nav ul a, .m-cmn-tab-nav ul button {display:block;height:5.2rem;border-bottom:2px solid transparent;font-size:1.6rem;line-height:5rem;color:#adb5bd;font-weight:600;}
  .m-cmn-tab-nav .active a, .m-cmn-tab-nav .active button {border-color:#000;color:#000;}
/* offering banner */
	.offering-banner {margin-left:1.6rem;margin-right:1.6rem;}
	.offering-banner .banner-wrap {
		display:-webkit-box;display:-moz-box;display:-ms-flexbox;display:-webkit-flex;display:flex;
		-webkit-box-align:center;-moz-box-align:center;-ms-box-align:center;align-items:center;-webkit-align-items:center;
		position:relative;width:100%;height:4rem;padding-left:1.6rem;border-radius:0.6rem;background:#4545ff;
		background:-moz-linear-gradient(left,  #4545ff 0%, #992dff 100%);
		background:-webkit-linear-gradient(left,  #4545ff 0%,#992dff 100%);
		background:linear-gradient(to right,  #4545ff 0%,#992dff 100%);
		filter:progid:DXImageTransform.Microsoft.gradient( startColorstr='#4545ff', endColorstr='#992dff',GradientType=1 );
	}
	.offering-banner .banner-wrap::after {content:'';position:absolute;right:1.2rem;top:0;bottom:0;width:1.2rem;height:1.6rem;margin:auto;background:url('../images/ico_arrow_white.png') no-repeat;background-size:1.2rem 1.6rem;}
	.offering-banner .banner-wrap .item {display:block;font-size:1.3rem;line-height:1.6rem;letter-spacing:-0.13px;color:#fff;}
	.offering-banner .banner-wrap .item:first-of-type {margin-right:1rem;font-weight:500;}
	.offering-banner.light-mode .banner-wrap {background:#00b2c7;}
/* etc */
	.caution-txt {margin-top:2.3rem;text-align:center;font-size:1rem;line-height:1.7rem;color:#adb5bd;}
	.flx-sti-footer .sol-caution-txt {padding-bottom:3rem;text-align:center;font-size:1.2rem;line-height:1.8rem;color:#868e96;}
	.flx-sti-footer .sol-caution-txt::before {content:'';display:inline-block;vertical-align:top;width:1.4rem;height:1.4rem;margin-top:0.2rem;background:url('../images/img_caution.png') no-repeat;background-size:1.4rem;}
	.sol-txt-btn-box {margin-top:-1.8rem;padding-bottom:3rem;text-align:center;}
	.sol-txt-btn-box button, .sol-txt-btn-box a {display:inline-block;vertical-align:top;font-size:1.4rem;line-height:2rem;color:#000;text-decoration:underline;}
	.sol-txt-btn-box button:active, .sol-txt-btn-box a:active {opacity:0.3;}
	.sol-txt-btn-box.alter-st {margin-top:0;}
	.sol-txt-btn-box.alter-st button, .sol-txt-btn-box.alter-st a {color:#868e96;}
	.img-block {padding:0 3rem;margin-top:3rem;}
	.img-block img {display:block;width:100%;}
/* dimmed loading */
	.dimmed-loading {position:fixed;left:0;top:0;right:0;bottom:0;z-index:20003;background:rgba(0,0,0,0.7);}
/* top banner box */
	.top-banner-box {margin:3rem 1.6rem 1.6rem 1.6rem;}
	.top-banner-box img {display:block;width:100%;border-radius:0.6rem;}
	.top-banner-box + .img-link-list {margin-top:0;}
/* image link list */
	.img-link-list {margin:3rem 1.6rem 0 1.6rem;}
	.img-link-list li {margin-top:1rem;}
	.img-link-list li:first-of-type {margin-top:0;}
	.img-link-list button, .img-link-list a, .img-link-list img {display:block;width:100%;border-radius:0.6rem;background-color:transparent;}
/* popup */
	.cmm-dimmed-layer {position:fixed;left:0;top:0;z-index:15000;width:100%;height:100%;min-height:100%;background:#000;opacity:0.7;}
	.cmm-dark-dimmed-layer {position:fixed;left:0;top:0;z-index:15000;width:100%;height:100%;min-height:100%;background:#000;opacity:0.85;}
	.pop-full-layer {overflow:auto;position:fixed;left:0;top:0;z-index:31000;width:100%;height:100%;background-color:#fff;-webkit-overflow-scrolling:touch;}
	.pop-outer {
		position:fixed;left:0;top:0;width:100%;height:100%;padding:0 3rem;z-index:20002;
		display:-webkit-box;display:-moz-box;display:-ms-flexbox;display:-webkit-flex;display:flex;
		-webkit-box-pack:center;-moz-box-pack:center;-ms-box-pack:center;-webkit-justify-content:center;justify-content:center;
		-webkit-box-align:center;-moz-box-align:center;-ms-box-align:center;-webkit-align-items:center;align-items:center;
		-moz-box-orient:vertical;-webkit-box-orient:vertical;box-orient:vertical;-webkit-flex-direction:column;flex-direction:column;
	}
	.pop-outer .ex-linker {position:relative;z-index:20004;display:inline-block;margin-top:1.2rem;font-weight:500;font-size:1.3rem;line-height:1.9rem;vertical-align:top;color:#fff;text-decoration:underline;}
	.pop-outer.sp-mode {padding:0 4rem;}
	.pop-outer.full-mode {padding:0;}
	.pop-inner {position:relative;z-index:20004;width:100%;border-radius:0.6rem;background-color:#fff;}
	.pop-inner .pop-closed {
		position:absolute;right:0;top:0;z-index:20004;overflow:hidden;width:5.6rem;height:5.2rem;
		text-indent:-999.9rem;background:url('../images/btn_close.png') 50% 50% no-repeat;background-size:3.2rem;
	}
	.pop-inner .pop-closed:active {opacity:0.3;}
	/* base text */
	.pop-inner .base-txt-wrap {padding:2rem 2rem 0 2rem;}
	.pop-inner .base-txt-box {min-height:6.6rem;word-break:keep-all;}
	.pop-inner .base-txt-box h1 {margin-bottom:1.6rem;font-size:1.5rem;line-height:2.2rem;font-weight:700;}
	.pop-inner .base-txt-box h1.ico {padding-left:2.2rem;background-repeat:no-repeat;background-position:0 50%;background-size:2rem;}
	.pop-inner .base-txt-box h1.ico.music {background-image:url('../images/ico_tcoloring_black.png');}
	.pop-inner .base-txt-box .g-txt {margin-bottom:0.8rem;font-size:1.5rem;line-height:2.2rem;}
	.pop-inner .base-txt-box .g-txt strong {font-weight:700;}
	.pop-inner .pop-btn-module {padding-right:0.8rem;text-align:right;}
	.pop-inner .pop-btn-module button, .pop-inner .pop-btn-module a {display:inline-block;padding:2rem 1.2rem;font-size:1.5rem;vertical-align:top;line-height:2.2rem;font-weight:500;color:#adb5bd;}
	.pop-inner .pop-btn-module button:active, .pop-inner .pop-btn-module a:active {opacity:0.3;}
	.pop-inner .pop-btn-module .acc {color:#000;}
	/* slide-up type */
	@-webkit-keyframes modal-on {
		0%		{bottom:-110%;}
		100%	{bottom:5.4rem;}
	}
	@keyframes modal-on {
		0%		{bottom:-110%;}
		100%	{bottom:5.4rem;}
	}
	@-webkit-keyframes modal-off {
		0%		{bottom:5.4rem;}
		100%	{bottom:-110%;}
	}
	@keyframes modal-off {
		0%		{bottom:5.4rem;}
		100%	{bottom:-110%;}
	}
	@-webkit-keyframes modal-nonav-on {
		0%		{bottom:-110%;}
		100%	{bottom:0;}
	}
	@keyframes modal-nonav-on {
		0%		{bottom:-110%;}
		100%	{bottom:0;}
	}
	@-webkit-keyframes modal-nonav-off {
		0%		{bottom:0;}
		100%	{bottom:-110%;}
	}
	@keyframes modal-nonav-off {
		0%		{bottom:0;}
		100%	{bottom:-110%;}
	}
	.pop-outer.slide-up-type .pop-inner .pop-closed {top:-4.4rem;width:4.4rem;height:4.4rem;background-image:url('../images/btn_close_white.png');}
	.pop-outer.slide-up-type {top:auto;bottom:-110%;height:auto;max-height:100%;opacity:0;display:block;padding:0;z-index:0;-webkit-transition-duration:200ms;-moz-transition-duration:200ms;-o-transition-duration:200ms;transition-duration:200ms;}
	.pop-outer.slide-up-type .pop-inner {width:100%;padding:0;border-top-left-radius:1.2rem;border-top-right-radius:1.2rem;border-bottom-left-radius:0;border-bottom-right-radius:0;}
	.pop-outer.slide-up-type .pop-inner.black-mode {border-radius:0;color:#fff;background-color:#000;}
	.pop-outer.slide-up-type .pop-inner.black-mode .pop-closed {right:0.6rem;top:0.6rem;width:3.2rem;height:3.2rem;background-image:url('../images/btn_close_white.png');}
	.pop-outer.slide-up-type .pop-inner.black-mode .ok-module {margin:2.4rem 0 0 0;padding:0 6.5rem 3.5rem 6.5rem;}
	.pop-outer.slide-up-type.active {opacity:1;z-index:20004;-webkit-animation:modal-on 200ms both;-moz-animation:modal-on 200ms both;-o-animation:modal-on 200ms both;animation:modal-on 200ms both;}
	.pop-outer.slide-up-type:not(.active) {-webkit-animation:modal-off 200ms both;-moz-animation:modal-off 200ms both;-o-animation:modal-off 200ms both;animation:modal-off 200ms both;}
	.no-main-nav .pop-outer.slide-up-type.active, .pop-outer.slide-up-type.bt-sticky-mode.active, .detail .pop-outer.slide-up-type.active {-webkit-animation:modal-nonav-on 200ms both;-moz-animation:modal-nonav-on 200ms both;-o-animation:modal-nonav-on 200ms both;animation:modal-nonav-on 200ms both;}
	.pop-outer.slide-up-type.no-main-nav.active {-webkit-animation:modal-nonav-on 200ms both;-moz-animation:modal-nonav-on 200ms both;-o-animation:modal-nonav-on 200ms both;animation:modal-nonav-on 200ms both;}
	.no-main-nav .pop-outer.slide-up-type:not(.active), .pop-outer.slide-up-type.bt-sticky-mode:not(.active), .detail .pop-outer.slide-up-type:not(.active) {-webkit-animation:modal-nonav-off 200ms both;-moz-animation:modal-nonav-off 200ms both;-o-animation:modal-nonav-off 200ms both;animation:modal-nonav-off 200ms both;}
	.pop-outer.slide-up-type.no-main-nav:not(.active) {-webkit-animation:modal-nonav-off 200ms both;-moz-animation:modal-nonav-off 200ms both;-o-animation:modal-nonav-off 200ms both;animation:modal-nonav-off 200ms both;}
	.pop-outer.slide-up-type .cmm-dimmed-layer, .pop-outer.slide-up-type .cmm-dark-dimmed-layer {display:none;}
	.pop-outer.slide-up-type.active .cmm-dimmed-layer, .pop-outer.slide-up-type.active .cmm-dark-dimmed-layer {display:block;}
	.choose-list-box button, .choose-list-box a {display:block;overflow:hidden;width:100%;height:5.2rem;text-align:center;font-size:1.4rem;color:#000;line-height:5.2rem;}
	.choose-list-box .active {color:#00b2c7;}
	.choose-list-box button:active, .choose-list-box a:active {background-color:rgba(0,0,0,0.1);}
	.choose-list-box button.acc, .choose-list-box a.acc {color:#ff5455;}
	.choose-list-box button:disabled, .choose-list-box a.disabled {opacity:0.3;}
	.choose-list-box button:disabled:active, .choose-list-box a.disabled:active {background-color:transparent;}
	.guide-info-box {padding:3rem 0 0 0;}
	.guide-info-box h1 .ico {display:block;width:5.2rem;margin:0 auto;margin-bottom:1.6rem;}
	.guide-info-box h1 {text-align:center;font-size:2rem;line-height:2.9rem;color:#000;font-weight:700;}
	.guide-info-box .g-txt {margin-top:0.8rem;text-align:center;font-size:1.4rem;line-height:2rem;color:#868e96;}
	.guide-info-box .btn-module {padding-top:2.4rem;padding-bottom:3rem;}
	.pop-alert-box {padding-top:3.5rem;text-align:center;}
	.pop-alert-box h1 {margin-left:2.4rem;margin-right:2.4rem;font-size:1.7rem;line-height:2.4rem;letter-spacing:-0.61px;}
	.pop-alert-box .ico-txt {margin-top:0.8rem;margin-left:2.4rem;margin-right:2.4rem;font-size:1.2rem;line-height:1.8rem;color:#00b2c7;}
	.pop-alert-box .ico-txt span {display:inline-block;vertical-align:top;line-height:1.8rem;}
	.pop-alert-box .ico-txt span::before {content:'';display:inline-block;width:1.2rem;height:1.2rem;margin:0.3rem 0.2rem 0 0;vertical-align:top;background:url('../images/ico_alert.png') 0 0 no-repeat;background-size:1.2rem;}
	/* input type */
	.pop-outer.inp-type {padding:0;display:block;}
	.pop-inp-wrap {position:relative;z-index:20004;width:100%;}
	.pop-inp-wrap .header {position:relative;background-color:transparent;}
	.pop-inp-wrap .sub-sect .btn-txt {color:#fff;}
	.pop-inp-wrap .sub-sect .btn-txt:disabled {color:#fff;opacity:0.3;}
	.pop-inp-wrap .sub-sect .btn-txt:active {opacity:0.3;}
	.pop-inp-wrap .sub-sect h1 {color:#fff;}
	.pop-inp-box {position:relative;padding:0 1.6rem;margin-top:10rem;}
	.pop-inp-box .inp-st {height:5.8rem;padding-right:3.6rem;border-bottom:1px solid #dee2e6;font-size:1.5rem;line-height:2rem;color:#000;}
	.pop-inp-box .inp-st:active, .pop-inp-box .inp-st:focus {border-bottom-color:#000;}
	.pop-inp-box.align-c .inp-st {padding-left:3.6rem;text-align:center;}
	.pop-inp-box .btn-del {display:none;position:absolute;right:0;top:0;overflow:hidden;width:5.2rem;height:5.8rem;text-indent:-999.9rem;background:url('../images/btn_close03.png') 50% 50% no-repeat;background-size:2rem;}
	.pop-inp-box .btn-del:active {opacity:0.3;}
	.pop-inp-box .btn-del.active {display:block;}
	.pop-outer .pop-inp-box .inp-st {border-bottom-color:#8a8787;color:#fff;}
	.pop-outer .pop-inp-box .inp-st:active, .pop-outer .pop-inp-box .inp-st:focus {border-bottom-color:#8a8787;}
	/* event popup */
	.evt-pop-inner {position:relative;z-index:20004;width:100%;border-radius:0.6rem;background-color:transparent;}
	.evt-pop-inner .img-part-box {position:relative;border-radius:0.6rem 0.6rem 0 0;background-color:transparent;}
	.evt-pop-inner .img-part-box .img-box {display:block;width:100%;border-radius:0.6rem 0.6rem 0 0;}
	.evt-pop-inner .img-part-box .img-line {overflow:hidden;display:block;height:43.8rem;border-top-left-radius:0.6rem;border-top-right-radius:0.6rem;text-indent:-999.9rem;background-position:50%;background-repeat:no-repeat;background-size:cover;}
	.evt-pop-inner .ctrl-part-box {display:-webkit-box;display:-moz-box;display:-ms-flexbox;display:-webkit-flex;display:flex;border-bottom-left-radius:0.6rem;border-bottom-right-radius:0.6rem;background-color:#fff;}
	.evt-pop-inner .ctrl-part-box button, .evt-pop-inner .ctrl-part-box a {display:block;width:50%;height:5.6rem;font-size:1.5rem;line-height:5.6rem;font-weight:500;text-align:center;}
	.evt-pop-inner .ctrl-part-box button:active, .evt-pop-inner .ctrl-part-box a:active {background-color:rgba(0,0,0,0.1);}
	.pop-outer.full-mode .evt-pop-inner {height:100%;}
	.pop-outer.full-mode .evt-pop-inner .img-part-box {height:calc(100% - 5.6rem);}
	.pop-outer.full-mode .img-line {height:100%;border-radius:0;}
	.pop-outer.full-mode .evt-pop-inner .img-part-box .img-box {position:absolute;left:50%;width:110%;margin-left:-55%;}
	.pop-outer.full-mode .ctrl-part-box {position:fixed;left:0;bottom:0;width:100%;border-radius:0;}
	/* app install */
	.app-install {padding-bottom:2.5rem;}
	.app-install .vi {display:block;width:100%;border-top-left-radius:0.6rem;border-top-right-radius:0.6rem;}
	.app-install h1 {margin-top:2.4rem;font-size:2rem;line-height:2.8rem;text-align:center;font-weight:700;}
	.app-install .ok-module {margin:2.4rem 0 0 0;padding:0 2.5rem;}
	/* sns */
	.pop-sns-box h1 {margin-bottom:2.8rem;padding:1.5rem 0 0 1.6rem;font-size:1.5rem;line-height:1.9rem;color:#000;font-weight:700;}
	.pop-sns-box ul {
		display:-webkit-box;display:-moz-box;display:-ms-flexbox;display:-webkit-flex;display:flex;
		-webkit-box-pack:justify;-ms-flex-pack:justify;-webkit-justify-content:space-between;justify-content:space-between;
		padding:0 3.9rem 2.4rem 3.9rem;
	}
	.pop-sns-box li {width:4.2rem;}
	.pop-sns-box button, .pop-sns-box a {overflow:hidden;width:4.2rem;height:4.2rem;text-indent:-999.9rem;background:url('../images/set_ico_sns.png') no-repeat;background-size:4.2rem auto;}
	.pop-sns-box button:active, .pop-sns-box a:active {opacity:0.3;}
	.pop-sns-box .url button, .pop-sns-box .url a {background-position:0 0;}
	.pop-sns-box .kakaotalk button, .pop-sns-box .kakaotalk a {background-position:0 -5.2rem;}
	.pop-sns-box .facebook button, .pop-sns-box .facebook a {background-position:0 -10.4rem;}
	.pop-sns-box .twitter button, .pop-sns-box .twitter a {background-position:0 -15.6rem;}
	.pop-sns-box .instagram button, .pop-sns-box .instagram a {background-position:0 -20.8rem;}
	.pop-sns-box .sms button, .pop-sns-box .sms a {background-position:0 -26rem;}
	/* other text box */
	.other-txt-box {padding:4rem 2rem 2.4rem 2rem;}
	.other-txt-box .g-txt {font-size:1.5rem;line-height:2.2rem;}
	.other-txt-box .ok-module {padding:0 0.5rem;}
	/* popup in the form box */
	.pop-outer .frm-box {padding:2rem 2rem 1rem 2rem;}
	.pop-outer .frm-box .txt-line {margin-bottom:1.6rem;font-size:1.5rem;line-height:2.2rem;color:#181818;}
	/* v coloring type popup */
	.v-coloring-type {padding:3.6rem 0 4rem 0;border-radius:0.6rem 0.6rem 0 0;text-align:center;word-break:keep-all;background-color:#f5f0eb;}
	.v-coloring-type:only-child {border-radius:0.6rem;}
	.v-coloring-type h1 {font-size:2.5rem;line-height:3.2rem;letter-spacing:-1px;font-weight:700;}
	.v-coloring-type .ok-module {margin:5rem 0 0 0;padding:0 3.5rem;}
	.v-coloring-type h1::before {content:'';display:block;width:5rem;height:5rem;margin:0 auto 0.8rem auto;background:url("../images/img_login_logo02.png") no-repeat;background-size:5rem;}
	.v-coloring-type .g-txt {margin:0.8rem 2.4rem 0 2.4rem;font-size:1.4rem;line-height:2.1rem;letter-spacing:-0.5px;color:#495057;}
	.v-coloring-type .sp-txt {margin:2rem 2.4rem 0 2.4rem;padding:1.8rem 0;border-radius:0.6rem;font-size:1.2rem;line-height:1.7rem;letter-spacing:-0.32px;color:#000;background-color:#eee7e1;}
	.sub-ok-module a, .sub-ok-module button {display:block;width:100%;height:5.6rem;line-height:5.6rem;font-size:1.6rem;color:#000;font-weight:700;text-align:center;}
	/* no secede */
	.no-secede .vi01 {display:block;width:100%;border-top-left-radius:0.6rem;border-top-right-radius:0.6rem;}
	.no-secede .vi02 {display:block;width:100%;border-bottom-left-radius:0.6rem;border-bottom-right-radius:0.6rem;}
	.no-secede .btn-block {display:block;}
	/* base other text box */
	.base-other-box {padding-bottom:2.4rem;}
	.base-other-box h1 {padding-top:2.4rem;font-size:2rem;line-height:2.8rem;text-align:center;font-weight:700;}
	.base-other-box p {margin-top:1rem;font-size:1.5rem;line-height:2.2rem;text-align:center;}
	.base-other-box .ok-module {margin:4rem 0 0 0;padding:0 2.5rem;}
	.base-other-box .ok-module button {width:100%;}
	/* item selector */
	.pop-item-selector h1 {padding:1.4rem 1.6rem 2.1rem 1.6rem;font-size:1.5rem;line-height:1.9rem;font-weight:700;}
	.pop-item-selector .inner-scr-box {overflow:auto;overflow-x:hidden;max-height:24.6rem;}
	.pop-item-selector .creator-profile {padding-bottom:2rem;}
	.pop-item-selector .creator-profile .item-wrap .thumb {width:6rem;height:6rem;border-radius:6rem;}
	.pop-item-selector .creator-profile .item-wrap {height:6rem;}
	.pop-item-selector .creator-profile.list-mode {padding-top:0;}
	.pop-item-selector .creator-profile.list-mode .row {margin-top:1.2rem;}
	.pop-item-selector .creator-profile.list-mode .row:first-of-type {margin-top:0;}
	/* terms checkbox block */
	.terms-chkbox-block {padding-top:4rem;padding-bottom:3rem;}
	.terms-chkbox-block h1 {text-align:center;color:#181818;}
	.terms-chkbox-block .chk-lists {padding:0 2rem;margin-top:2.4rem;}
	.terms-chkbox-block h1 em {display:block;font-size:1.4rem;line-height:2rem;font-weight:700;}
	.terms-chkbox-block h1 strong {display:block;margin-top:0.4rem;font-size:2rem;line-height:2.4rem;font-weight:700;}
	.terms-chkbox-block .ok-module {padding:0;}
	.terms-chkbox-block .ok-module button {width:23rem;margin:0 auto;}
	.terms-chkbox-block .cookie-ctrl-block {margin-top:1.2rem;text-align:center;}
	.terms-chkbox-block .cookie-ctrl-block .frm-chk.st05[type='checkbox'] + label {padding-left:2rem;font-size:1.3rem;color:#868e96;}
	.terms-chkbox-block .cookie-ctrl-block .frm-chk.st05[type='checkbox'] + label::before {width:2rem;height:2rem;margin-top:-1rem;background-image:url('../images/btn_chk_off02.png');background-size:2rem;}
/* selection */
	.sel-tit-box {padding:1rem 1.6rem 0 1.6rem;color:#000;text-align:center;}
	.sel-tit-box h1 {font-size:3rem;line-height:3.9rem;font-weight:700;}
	.sel-tit-box .g-txt {margin-top:1.6rem;font-size:1.5rem;line-height:2.1rem;}
	.sel-grid-box {padding:4.8rem 3rem 0 3rem;display:grid;grid-template-columns:repeat(3, 1fr);gap:1.7rem 0;}
	.sel-grid-box .frm-chk.img-st[type='checkbox'] + label {display:block;margin:0;white-space:normal;text-align:center;font-size:1.4rem;line-height:1.7rem;font-weight:500;color:#000;}
	.sel-grid-box .frm-chk.img-st[type='checkbox'] + label::before {display:none;}
	.sel-grid-box .frm-chk.img-st[type='checkbox'] + label .thumb {position:relative;display:block;width:8.4rem;height:8.4rem;margin-bottom:0.6rem;border-radius:8.4rem;}
	.sel-grid-box .frm-chk.img-st[type='checkbox'] + label .thumb::before {border-radius:100%;}
	.sel-grid-box .frm-chk.img-st[type='checkbox']:checked + label .thumb::before {content:'';position:absolute;left:0;top:0;z-index:2;width:100%;height:100%;border-radius:100%;background:url('../images/btn_like_selected.png') no-repeat;background-size:8.4rem;}
	.sel-grid-box .inner-txt {display:block;}
	.sel-grid-box li:nth-of-type(3n-2) {justify-self:start;}
	.sel-grid-box li:nth-of-type(3n-1) {justify-self:center;}
	.sel-grid-box li:nth-of-type(3n) {justify-self:end;}
/* solo button box */
	.sol-btn-box {margin:2.4rem 1.6rem 0 1.6rem;}
	.sol-btn-box a, .sol-btn-box button {display:block;width:100%;height:5.8rem;border:1px solid #000;border-radius:0.5rem;text-align:center;}
	.sol-btn-box a span, .sol-btn-box button span {display:inline-block;overflow:hidden;height:5.6rem;padding-left:1.7rem;vertical-align:top;line-height:5.6rem;font-size:1.5rem;font-weight:700;background:url('../images/img_plus_black.png') 0 50% no-repeat;background-size:1.1rem;}
/* coupon list box */
	.coupon-list-box {overflow:hidden;height:calc(100% - 12.8rem);margin:0 1.6rem;padding-top:1.2rem;}
	.coupon-list-box .row {margin-top:1.2rem;}
	.coupon-list-box .item-wrap {
		display:-webkit-box;display:-moz-box;display:-ms-flexbox;display:-webkit-flex;display:flex;
		-webkit-box-pack:center;-moz-box-pack:center;-ms-box-pack:center;box-pack:center;-webkit-justify-content:center;justify-content:center;
		-moz-box-orient:vertical;-webkit-box-orient:vertical;box-orient:vertical;-webkit-flex-direction:column;flex-direction:column;
		position:relative;width:100%;height:12rem;padding:0 5.5rem 0 2.4rem;border:1px solid #868e96;border-radius:0.8rem;
	}
	.coupon-list-box .item-wrap.selected, .coupon-list-box .item-wrap.selected::before, .coupon-list-box .item-wrap.selected::after {border-color:#00b2c7;}
	.coupon-list-box .item-wrap.selected .tit::before {position:absolute;right:2.3rem;top:50%;z-index:1;content:'';width:3.2rem;height:3.2rem;margin-top:-1.6rem;background:url('../images/btn_selected.png') no-repeat;background-size:3.2rem;}
	.coupon-list-box .item-wrap::before, .coupon-list-box .item-wrap::after {box-sizing:border-box;-moz-box-sizing:border-box;-webkit-box-sizing:border-box;position:absolute;top:50%;z-index:10;content:'';width:2.2rem;height:2.2rem;margin-top:-1.1rem;border-radius:1.1rem;border:1px solid #868e96;border-right:1px solid transparent;border-bottom:1px solid transparent;background-color:#fff;}
	.coupon-list-box .item-wrap::after {right:-1.2rem;-webkit-transform:rotate(-45deg);transform:rotate(-45deg);}
	.coupon-list-box .item-wrap::before {left:-1.2rem;-webkit-transform:rotate(135deg);transform:rotate(135deg);}
	.coupon-list-box .item-wrap:disabled, .coupon-list-box .item-wrap.disabled {border-color:#dee2e6;background-color:#f8f9fa;}
	.coupon-list-box .item-wrap:disabled .tit, .coupon-list-box .item-wrap.disabled .tit,
	.coupon-list-box .item-wrap:disabled .m-txt, .coupon-list-box .item-wrap.disabled .m-txt {color:rgba(0,0,0,0.3);}
	.coupon-list-box .item-wrap:disabled .s-txt, .coupon-list-box .item-wrap.disabled .s-txt {color:rgba(134,142,150,0.3);}
	.coupon-list-box .item-wrap:disabled::after, .coupon-list-box .item-wrap:disabled::before,
	.coupon-list-box .item-wrap.disabled::after, .coupon-list-box .item-wrap.disabled::before {border-color:#dee2e6;}
	.coupon-list-box .item-wrap .tit, .coupon-list-box .item-wrap .m-txt, .coupon-list-box .item-wrap .s-txt {display:block;}
	.coupon-list-box .item-wrap .tit {font-size:1.6rem;line-height:2.4rem;font-weight:700;color:#000;}
	.coupon-list-box .item-wrap .m-txt {margin-top:0.8rem;font-size:1.4rem;line-height:2rem;color:#000;}
	.coupon-list-box .item-wrap .s-txt {margin-top:0.4rem;font-size:1.4rem;line-height:1.6rem;color:#868e96;}
	.coupon-list-box .sol-msg-txt {
		display:-webkit-box;display:-moz-box;display:-ms-flexbox;display:-webkit-flex;display:flex;
		-webkit-box-pack:center;-moz-box-pack:center;-ms-box-pack:center;box-pack:center;-webkit-justify-content:center;justify-content:center;
		-webkit-box-align:center;-moz-box-align:center;-ms-box-align:center;box-align:center;-webkit-align-items:center;align-items:center;
		-webkit-box-orient:vertical;box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;
		height:100%;padding-top:8rem;padding-bottom:11.2rem;text-align:center;font-size:1.4rem;line-height:2.1rem;color:#868e96;
	}
/* tutorial process refine */
	.tutorial-wrap {
		height:calc(100vh - 5.2rem);
		display:-webkit-box;display:-moz-box;display:-ms-flexbox;display:-webkit-flex;display:flex;
		-webkit-box-pack:justify;-ms-flex-pack:justify;-webkit-justify-content:space-between;justify-content:space-between;
		-webkit-box-orient:vertical;box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;
	}
	@supports (height: 100dvh) {
		.tutorial-wrap {
			height: calc(100dvh - 5.2rem);
		}
	}
	.tut-video-box {padding-top:4.8rem;padding-bottom:5rem;}
	.tut-video-box video {display:block;width:21.2rem;margin:0 auto;}
/* special fixed module */
	.sp-fixed-module {position:fixed;left:0;right:0;bottom:7.8rem;z-index:30;text-align:center;-webkit-transform:translateY(200%);transform:translateY(200%);-webkit-transition:transform 400ms ease-in-out;transition:transform 400ms ease-in-out;}
	.sp-fixed-module.active {-webkit-transform:translateY(0);transform:translateY(0);}
	.sp-fixed-module .auto-setting {width:10rem;height:3.8rem;border-radius:3.8rem;box-shadow:0 0.4rem 0.8rem rgba(0,0,0,0.2);transition:all 300ms;-webkit-transition:all 300ms;}
	.sp-fixed-module .auto-setting .inner-line {border-radius:3.6rem;line-height:3.6rem;}
	.sp-fixed-module .auto-setting .inner-line .inner-txt {height:3.6rem;padding-left:2.4rem;line-height:3.6rem;}
	.sp-fixed-module .auto-setting .inner-line .inner-txt::before {width:2.2rem;height:2.2rem;background:url('../images/set_ico_shuffle.png') no-repeat;background-size:auto 2.2rem;-webkit-transition:all 300ms;transition:all 300ms;}
	.sp-fixed-module .auto-setting .inner-line .inner-txt em {font-weight:500;-webkit-transition:opacity 50ms;transition:opacity 50ms;}
	.sp-fixed-module .auto-setting.active {width:8.8rem;}
	.sp-fixed-module .auto-setting.active .inner-line {border-radius:3.8rem;line-height:3.8rem;}
	.sp-fixed-module .auto-setting.active .inner-line .inner-txt {height:3.8rem;line-height:3.8rem;}
	.sp-fixed-module .auto-setting.active .inner-line .inner-txt::before {background-position:-3.2rem 0;}
	.sp-fixed-module .auto-setting.reduction {width:3.8rem;}
	.sp-fixed-module .auto-setting.reduction .inner-line .inner-txt {padding-left:0;}
	.sp-fixed-module .auto-setting.reduction .inner-line .inner-txt em {opacity:0;}
	.sp-fixed-module .auto-setting.reduction .inner-line .inner-txt::before {left:50%;right:auto;-webkit-transform:translateX(-50%);transform:translateX(-50%);}
/* button + message box */
	.sub-container .btn-msg-box {
		display:-webkit-box;display:-moz-box;display:-ms-flexbox;display:-webkit-flex;display:flex;
		-webkit-box-pack:center;-moz-box-pack:center;-ms-box-pack:center;-webkit-justify-content:center;justify-content:center;
		-webkit-box-align:center;-moz-box-align:center;-ms-box-align:center;-webkit-align-items:center;align-items:center;
		-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;
		height:100%;padding:12rem 0;text-align:center;
	}
	.sub-container .btn-msg-box .g-txt {font-size:1.4rem;line-height:2.1rem;color:#868e96;}
	.sub-container .btn-msg-box .inner-btn-module {margin-top:1.6rem;}
	.sub-container .btn-msg-box .inner-btn-module button {display:block;width:18.2rem;height:3.8rem;border:1px solid #ced4da;border-radius:3.8rem;font-weight:500;font-size:1.4rem;text-align:center;line-height:3.6rem;}
/* card stack box */
	.card-stack-box {position:relative;display:block;-webkit-transition:transform 0.3s ease-in-out;transition:transform 0.3s ease-in-out;}
	.tab-cont-swiper .card-stack-box {margin:1rem 1.6rem 0 1.6rem;}
	.card-stack-box:first-child {margin-top:0;}
	.card-stack-box .be-filled-with {margin:0;}
	.card-stack-box::before, .card-stack-box::after {content:"";position:absolute;top:0;right:0;bottom:0;left:0;border-radius:0.6rem;-webkit-transition:transform 0.3s ease-in-out;transition:transform 0.3s ease-in-out;-webkit-transform-origin:center bottom;transform-origin:center bottom;}
	.card-stack-box::before {z-index:-1;background-color:#ced4da;}
	.card-stack-box::after {z-index:-2;background-color:#e9ecef;}
	.card-stack-box.auto-setting-mode::before {-webkit-transform:translate(0, 0.6rem) scale(0.95);transform:translate(0, 0.6rem) scale(0.95);}
	.card-stack-box.auto-setting-mode::after {-webkit-transform:translate(0, 1.2rem) scale(0.9);transform:translate(0, 1.2rem) scale(0.9);}
/* main title box */
	.m-tit-box {position:relative;padding-right:5rem;margin:4rem 1.6rem 1rem 1.6rem;}
	.m-tit-box h2 {overflow:hidden;font-size:1.8rem;line-height:2.1rem;font-weight:700;white-space:nowrap;text-overflow:ellipsis;}
	.m-tit-box h2 .on {display:inline-block;vertical-align:top;width:2.8rem;height:2.8rem;margin:-0.2rem 0.5rem 0 0;}
	.m-tit-box .more {position:absolute;right:0;top:0;bottom:0;height:2.1rem;margin:auto;font-size:1.4rem;line-height:2.1rem;color:#868e96;}
	.m-tit-box .more:active {opacity:0.3;}
	.m-tit-box h2.img-type {overflow:hidden;height:3.6rem;line-height:3.6rem;font-size:1.4rem;}
	.m-tit-box h2.img-type .ico {height:3.6rem;margin-right:1.2rem;border-radius:3.6rem;vertical-align:top;}
	.m-tit-box.grp-type {margin-top:1.8rem;}
	.s-tit-box {margin:-0.8rem 1.6rem 0 1.6rem;}
	.s-tit-box h3 {font-size:1.2rem;line-height:1.8rem;color:#868e96;}
/* main tag list */
  .m-tag-list {
    display:-webkit-box;display:-moz-box;display:-ms-flexbox;display:-webkit-flex;display:flex;
    flex-wrap:wrap;gap:1rem 0.6rem;
    margin:0 1.3rem;
  }
  .m-tag-list button, .m-tag-list a {
		display:-webkit-inline-box;-webkit-box-orient:vertical;overflow:hidden;text-overflow:ellipsis;-webkit-line-clamp:1;
		height:4rem;border-radius:4rem;padding:0 1.2rem;line-height:4rem;font-size:1.4rem;vertical-align:top;background-color:#f8f9fa;
	}
  .m-tag-list button:active, .m-tag-list a:active {opacity:0.3;}
	.m-tag-list em {color:#00b2c7;}
	.m-tag-list.limited {overflow:hidden;max-height:9rem;}
/* swiper : common */
	/* portrait type */
	.swiper-container.portrait-type {padding:0 1.6rem;}
	.swiper-container.portrait-type .swiper-slide {width:10rem;}
	.swiper-container.portrait-type .linker-wrap .thumb {height:16rem;}
	.swiper-container.portrait-type .linker-wrap .thumb-wrap {height:16rem;border-radius:0.6rem;}
	.swiper-container.portrait-type .linker-wrap .thumb-wrap .blur-thumb {left:-2rem;top:-2rem;right:-2rem;bottom:-2rem;width:auto;}
	.swiper-container.portrait-type .linker-wrap .thumb-wrap .overlay-thumb {width:100%;}
	.swiper-container.portrait-type .linker-wrap .txt-line {padding-bottom:0;}
  .swiper-container.portrait-type .linker-wrap .txt-line .m-tit {margin-top:1rem;font-size:1.3rem;line-height:1.6rem;}
  .swiper-container.portrait-type .linker-wrap .txt-line .s-txt {margin-top:0.4rem;font-size:1.2rem;line-height:1.4rem;}
  /* circle type */
  .swiper-container.circle-type {padding:0 1.2rem;}
  .swiper-container.circle-type .swiper-slide {width:9.8rem;}
  .swiper-container.circle-type .linker-wrap {text-align:center;}
	.swiper-container.circle-type .linker-wrap .thumb {width:9rem;height:9rem;margin:auto;border-radius:9rem;background-position:50%;}
  .swiper-container.circle-type .linker-wrap .thumb img {border-radius:100%;}
	.swiper-container.circle-type .linker-wrap .thumb::before {border:1px solid rgba(0,0,0,0.08);border-radius:100%;background-color:transparent;}
  .swiper-container.circle-type .linker-wrap .m-txt {
		display:-webkit-inline-box;display:-webkit-inline-flex;display:inline-flex;
		-webkit-box-pack:center;-moz-box-pack:center;-ms-box-pack:center;-webkit-justify-content:center;justify-content:center;
		-webkit-box-align:center;-moz-box-align:center;-ms-box-align:center;align-items:center;-webkit-align-items:center;
		max-width:7rem;margin-top:1rem;text-align:center;font-size:1.4rem;line-height:1.7rem;
	}
	.swiper-container.circle-type .linker-wrap .m-txt em {color:#00b2c7;}
	.swiper-container.circle-type .linker-wrap .m-txt .txt-item {overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}
	.swiper-container.circle-type .img-ico {display:block;width:1.3rem;height:1.3rem;margin-left:0.2rem;}
	.swiper-container.circle-type .linker-wrap:disabled, .swiper-container.circle-type .linker-wrap.disabled {opacity:0.3;}
/* help text box */
	.help-txt-box {padding:5rem 3rem;letter-spacing:-0.4px;word-break:keep-all;}
	.help-txt-box.other-space {padding:4rem 1.6rem 0 1.6rem;}
	.no-main-nav .sub-container .help-txt-box + .btn-module {padding-bottom:0;margin-top:0;}
	.help-txt-box h3 {margin-bottom:1rem;font-size:1.5rem;line-height:2.2rem;font-weight:700;}
	.help-txt-box p {position:relative;padding-left:0.8rem;margin-top:0.6rem;font-size:1.2rem;line-height:1.7rem;color:#868e96;}
	.help-txt-box p::before {position:absolute;left:0;top:0.4rem;content:'';width:0.4rem;height:0.4rem;border-radius:0.4rem;background-color:#ced4da;}
/* noti bubbler */
	.noti-bubbler {position:fixed;left:1.6rem;bottom:7rem;right:1.6rem;z-index:200;border-radius:0.6rem;padding:1.7rem 10rem 1.7rem 1.6rem;;box-shadow:0 0.8rem 1.6rem rgba(0,0,0,0.15);background-color:#eef6f6;}
	.noti-bubbler p {font-size:1.3rem;line-height:1.9rem;color:#000;}
	.noti-bubbler::after {content:'';position:absolute;z-index:1;top:-1rem;left:25%;width:0;border-style:solid;border-width:0 0.6rem 1.1rem;margin-left:-0.6rem;border-color:#eef6f6 transparent;}
	.noti-bubbler .inner-btn {position:absolute;right:1.6rem;top:1.7rem;font-size:1.3rem;line-height:1.9rem;font-weight:500;color:#000;}
	.no-main-nav .noti-bubbler {bottom:2rem;}
/* creator profile */
	.creator-profile {margin:4rem 1.6rem;}
	.creator-profile.list-mode {margin-top:0;margin-bottom:0;padding-top:0.8rem;}
	.creator-profile.list-mode .row {margin-top:1rem;}
	.creator-profile.list-mode .row:first-of-type {margin-top:0;}
	.creator-profile .item-wrap {
		display:inline-flex;
		-webkit-box-align:center;-moz-box-align:center;-ms-box-align:center;align-items:center;-webkit-align-items:center;
		height:7rem;max-width:100%;
	}
	.creator-profile .item-wrap .thumb {width:7rem;height:7rem;margin-right:1.2rem;border-radius:7rem;background-position:50%;background-size:cover;}
	.creator-profile .item-wrap .thumb::before {border:1px solid rgba(0,0,0,0.08);border-radius:100%;background-color:transparent;}
	.creator-profile .item-wrap .m-tit {
		-moz-flex:1;-webkit-flex:1;flex:1;
		overflow:hidden;text-overflow:ellipsis;white-space:nowrap;
		font-size:2rem;line-height:2.4rem;font-weight:500;
	}
	.creator-profile .item-wrap .s-txt {
		-moz-flex:1;-webkit-flex:1;flex:1;
		overflow:hidden;text-overflow:ellipsis;white-space:nowrap;
		font-size:1.5rem;line-height:1.8rem;color:#000;
	}
	.creator-profile .item-wrap .img-ico {display:block;width:1.6rem;height:1.6rem;margin-left:0.2rem;}
	.creator-profile em {color:#00b2c2;}
/* profile line box */
	.profile-line-box {position:relative;z-index:105;height:7rem;margin:1rem 1.6rem 2rem 1.6rem;padding-left:8.2rem;}
	.profile-line-box dt {margin-right:3rem;overflow:hidden;font-weight:500;font-size:2rem;line-height:7rem;white-space:nowrap;text-overflow:ellipsis;color:#000;}
	.profile-line-box.multi-line dt {
		display:-webkit-box;display:-moz-box;display:-ms-flexbox;display:-webkit-flex;display:flex;
		-webkit-box-pack:center;-moz-box-pack:center;-ms-box-pack:center;-webkit-justify-content:center;justify-content:center;
		-webkit-box-orient:vertical;-moz-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;
		height:7rem;line-height:2.9rem;white-space:normal;
	}
	.profile-line-box.multi-line .tit-block {display:block;overflow:hidden;line-height:2.9rem;white-space:nowrap;text-overflow:ellipsis;}
	.profile-line-box.multi-line .sp-block {display:block;font-size:1.3rem;line-height:1.9rem;color:#00b2c7;}
	.profile-line-box.multi-line .sp-block .ico {display:inline-block;padding-left:1.9rem;vertical-align:top;font-weight:500;}
	.profile-line-box.multi-line .sp-block .ico.adot {background:url('../images/img_adot.png') 0 50% no-repeat;background-size:1.9rem;}
	.profile-line-box .thumb-box-wrap {position:absolute;left:0;top:0;}
	.profile-line-box .thumb {position:relative;width:7rem;height:7rem;overflow:hidden;border-radius:7rem;text-indent:-999.9rem;background-size:cover;background-color:transparent;}
	.profile-line-box .thumb::before {content:'';position:absolute;left:0;top:0;right:0;bottom:0;z-index:1;border:1px solid rgba(0,0,0,0.08);border-radius:100%;}
	.profile-line-box .btn-edit-wrap {position:absolute;right:-1rem;top:50%;-webkit-transform:translateY(-50%);transform:translateY(-50%);}
	.profile-line-box .btn-edit-wrap .btn-edit {width:4rem;height:4rem;overflow:hidden;text-indent:-999.9rem;background-color:transparent;background:url('../images/btn_edit.png') 50% 50% no-repeat;background-size:2rem;}
	.profile-line-box + .ctrl-line-box {margin-top:0;}
	.profile-line-box.two-line-ellipsis dt {
		display:-webkit-box;display:-moz-box;display:-ms-flexbox;display:-webkit-flex;display:flex;
		-webkit-box-pack:center;-moz-box-pack:center;-ms-box-pack:center;-webkit-justify-content:center;justify-content:center;
		-webkit-box-orient:vertical;-moz-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;
		height:7rem;margin-right:0;line-height:2.4rem;white-space:normal;
	}
	.profile-line-box.two-line-ellipsis dt .inner-tit {
		display:-webkit-box;-webkit-box-orient:vertical;overflow:hidden;text-overflow:ellipsis;-webkit-line-clamp:2;
		max-height:4.8rem;margin-right:0;white-space:normal;
	}
	.p-msg-txt {margin:-0.8rem 1.6rem 0 1.6rem;font-size:1.4rem;line-height:2.2rem;color:#495057;word-break:keep-all;}
	.p-msg-txt .row {display:block;margin-top:0.8rem;}
	.p-msg-txt .row:first-of-type {margin-top:0;}
	.p-msg-txt .txt-block {display:inline-block;max-width:85%;vertical-align:top;}
	.p-msg-txt .inner-block {display:inline-block;max-width:85%;margin-bottom:-0.3rem;vertical-align:top;word-break:break-all;}
	.p-msg-txt .inner-block a, .p-msg-txt .inner-block button {
		display:-webkit-box;-webkit-box-orient:vertical;overflow:hidden;text-overflow:ellipsis;-webkit-line-clamp:2;
		font-size:1.3rem;line-height:2rem;color:#495057;word-break:break-all;
	}
	.p-msg-txt .inner-block a::before, .p-msg-txt .inner-block button::before {content:'';display:inline-block;width:1.6rem;height:1.6rem;margin:0.2rem 0.2rem 0 0;vertical-align:top;background:url('../images/ico_link.png') no-repeat;background-size:1.6rem;}
/* my plan */
	.my-plan .header {background-color:transparent;}
	.my-plan .header .sub-sect .btn.back {background-image:url('../images/btn_back_white.png');}
	.my-plan .header .sub-sect .btn-txt {color:#fff;}
	.my-plan .sol-tit-box.other-st h1 {color:#fff;}
	.my-plan.unlimited {
		background:#2445ff;
		background:-moz-linear-gradient(top,  #2445ff 0%, #7553ff 100%);
		background:-webkit-linear-gradient(top,  #2445ff 0%,#7553ff 100%);
		background:linear-gradient(to bottom,  #2445ff 0%,#7553ff 100%);
	}
	.my-plan.light {
		background:#0eacbf;
		background:-moz-linear-gradient(top,  #0eacbf 0%, #39bfbc 100%);
		background:-webkit-linear-gradient(top,  #0eacbf 0%,#39bfbc 100%);
		background:linear-gradient(to bottom,  #0eacbf 0%,#39bfbc 100%);
	}
	.my-plan .flx-sti-footer .ok-module button, .my-plan .flx-sti-footer .ok-module a {border-radius:4.6rem;font-weight:500;background:#fff;}
	.my-plan .flx-sti-footer .ok-module button.sp, .my-plan .flx-sti-footer .ok-module a.sp {color:#fff;background:#030422;}
	.simple-my-plan {margin-top:4rem;color:#fff;}
	.simple-my-plan h2 {font-size:1.5rem;line-height:1.8rem;font-weight:500;letter-spacing:-0.5px;text-align:center;}
	.simple-my-plan .simple-info-box {width:29rem;padding:2rem;border:1px solid rgba(255,255,255,0.1);border-radius:0.6rem;margin:2.8rem auto 0 auto;box-shadow:0 2rem 4rem rgba(0,0,0,0.15);background-color:rgba(255,255,255,0.15)}
	.simple-my-plan .simple-info-box .m-tit {
		display:-webkit-box;display:-moz-box;display:-ms-flexbox;display:-webkit-flex;display:flex;
		-webkit-box-pack:justify;-ms-flex-pack:justify;-webkit-justify-content:space-between;justify-content:space-between;
	}
	.simple-my-plan .simple-info-box .m-tit h3 {-moz-flex:1;-webkit-flex:1;flex:1;font-size:2.2rem;line-height:3rem;font-weight:700;}
	.simple-my-plan .simple-info-box .m-tit .status {margin-left:0.4rem;height:2.3rem;padding:0 0.9rem;margin-top:0.5rem;border:1px solid rgba(255,255,255,0.6);border-radius:2.3rem;line-height:2.1rem;}
	.simple-my-plan .simple-info-box .g-txt {margin-top:0.8rem;font-size:1.4rem;line-height:2rem;}
	.simple-my-plan .simple-info-box ul {border-top:1px solid rgba(255,255,255,0.2);margin-top:2rem;padding-top:2rem;font-size:1.3rem;line-height:2.2rem;}
	.simple-my-plan .simple-info-box li {display:-webkit-box;display:-moz-box;display:-ms-flexbox;display:-webkit-flex;display:flex;}
	.simple-my-plan .simple-info-box .inner-tit {margin-right:0.6rem;}
	.simple-my-plan .tip-txt {margin:2rem 4rem 0 4rem;text-align:center;font-size:1.3rem;line-height:2.2rem;font-weight:500;color:rgba(255,255,255,0.8);white-space:pre-line;}
/* list rectangle box */
	.list-rect-box {margin:3rem 1.6rem 0 1.6rem;color:#000;}
	.list-rect-box .row-block {border:1px solid #868e96;border-radius:0.3rem;padding:1.9rem;margin-top:1rem;}
	.list-rect-box .row-block.dimmed {opacity:0.3;}
	.list-rect-box .row-block:first-of-type {margin-top:0;}
	.list-rect-box .inner-head,
	.list-rect-box .inner-body .primary-information,
	.list-rect-box .inner-body .secondary-information,
	.list-rect-box .inner-foot .date {display:flex;align-items:center;justify-content:space-between;margin-top:1.6rem;}
	.list-rect-box .inner-foot .date:first-of-type {margin-top:0;}
	.list-rect-box .inner-body .primary-information {align-items:flex-start;margin-top:0;}
	.list-rect-box .inner-body .primary-information .right-side {margin-left:1rem;}
	.list-rect-box .inner-body h2 {font-size:1.6rem;line-height:2.2rem;font-weight:700;}
	.list-rect-box .inner-body h3 {margin-top:0.6rem;font-size:1.2rem;line-height:1.4rem;color:#495057;}
	.list-rect-box .inner-body h4 {margin-top:0.6rem;font-size:1.4rem;line-height:1.6rem;}
	.list-rect-box .inner-body .secondary-information {margin-top:2rem;padding-top:2rem;border-top:1px solid #dee2e6;}
	.list-rect-box .inner-body .s-txt {display:block;font-size:1.4rem;line-height:1.6rem;}
	.list-rect-box .inner-head {margin-bottom:1.6rem;margin-top:0;font-size:1.5rem;line-height:2.2rem;}
	.list-rect-box .inner-head .m-tit {font-weight:700;}
	.list-rect-box .inner-body {padding:2rem;border-radius:0.3rem;background-color:#f8f9fa;}
	.list-rect-box .thumb {width:9rem;height:9rem;}
	.list-rect-box .inner-foot {margin-top:1.6rem;}
	.list-rect-box .inner-foot del {margin-right:0.6rem;font-size:1.5rem;line-height:1.8rem;color:#868e96;}
	.list-rect-box .inner-foot h5 {font-size:1.5rem;line-height:1.8rem;font-weight:700;}
	.list-rect-box .inner-foot .date .left-side {display:flex;align-items:center;}
	.list-rect-box .inner-foot .date .s-txt {font-size:1.2rem;line-height:1.4rem;color:#868e96;}
	.list-rect-box .inner-foot .ok-module {margin-top:1.6rem;padding:0;}
	.list-rect-box .inner-foot .ok-module button, .list-rect-box .inner-foot .ok-module a {height:3.4rem;border-radius:3.4rem;line-height:3.4rem;font-size:1.3rem;font-weight:500;}
	.list-rect-box .s-flag {height:1.8rem;margin-right:0.6rem;padding:0 0.5rem;line-height:1.8rem;font-size:1.2rem;font-weight:500;}
	.list-rect-box .inner-foot .msg-box {text-align:center;font-size:1.4rem;line-height:1.7rem;}
	.list-rect-box .inner-foot .msg-box strong {font-weight:700;}
	.list-rect-box .ok-module {padding:0;margin-top:1.6rem;}
	.list-rect-box .ok-module button {height:3.4rem;border-radius:3.4rem;line-height:3.4rem;font-size:1.3rem;font-weight:500;}

