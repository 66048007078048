@charset 'utf-8';
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;500;700&family=Roboto:wght@300;400;700&display=swap');

/* SK Planet - Base 1.0 */
html,body,form,div,p,h1,h2,h3,h4,h5,h6,dl,dt,dd,ul,ol,li,fieldset,th,td,button{margin:0;padding:0;border:0;font-size:100%;font:inherit;}
body{-webkit-text-size-adjust:none;}
input,textarea,select,button{font:inherit;vertical-align:middle;padding:0;}
input[type='button'],input[type='text'],input[type='image'],input[type='submit'],input[type='password'],input[type='email'],input[type='tel'],textarea{-webkit-appearance:none;border-radius:0;}
input[type='checkbox']{-webkit-appearance:checkbox;}
input[type='radio']{-webkit-appearance:radio;}
textarea{resize:none;}
table{border-collapse:collapse;border-spacing:0;}
ol,ul,li{list-style:none;}
img,fieldset,iframe{border:none;}
address,cite,code,dfn,em,var,th,strong{font-style:normal;font-weight:normal;}
a{text-decoration:none;}
article,aside,dialog,figure,footer,header,hgroup,nav,section{display:block;}

/* ----- Common ----- */
* {-webkit-tap-highlight-color:rgba(0,0,0,0);box-sizing:border-box;-moz-box-sizing:border-box;-webkit-box-sizing:border-box;}
html {position:relative;min-height:100%;font-size:10px;line-height:16px;}
body {overflow-x:hidden;color:#1e1e1e;font-family:'Roboto', 'Noto Sans KR', sans-serif;font-weight:400;word-break:break-all;}
html, body {height:100%;}
a {cursor:pointer;color:#1e1e1e;}
i {font-style:normal;}
legend, hr {display:none;}
textarea {border:0;margin:0;}
button {cursor:pointer;outline:transparent;border:none;width:auto;margin:0;padding:0;text-align:left;background-color:transparent;}
img {vertical-align:top;}
.skip-nav, .hide {position:absolute;overflow:hidden;clip:rect(0 0 0 0);width:1px;height:1px;border:0;padding:0;}
.wrap {width:100%;max-width:100%;min-width:129.2rem;height:100%;}
.section-intro {position:fixed;top:0;right:0;bottom:0;left:0;z-index:700;overflow:hidden;overflow-x:auto;height:100%;}
/* paging */
	.swiper-vertical.bridge-page>.swiper-pagination-bullets {position:fixed;right:3.5rem;top:50%;z-index:100;-webkit-transform:translateY(-50%);-ms-transform:translateY(-50%);transform:translateY(-50%);}
	.swiper-vertical.bridge-page>.swiper-pagination-bullets .swiper-pagination-bullet {outline:transparent;opacity:1;display:block;margin:1rem 0.5rem;background-color:rgba(30,30,30,0.15);}
	.swiper-vertical.bridge-page>.swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {opacity:1;background-color:rgba(30,30,30,1);}
	.swiper-vertical.bridge-page>.swiper-pagination-bullets.w-mode .swiper-pagination-bullet {background-color:rgba(255,255,255,0.3);}
	.swiper-vertical.bridge-page>.swiper-pagination-bullets.w-mode .swiper-pagination-bullet.swiper-pagination-bullet-active {background-color:rgba(255,255,255,1);}
/* global class */
	.swiper-container, .swiper-wrapper, .swiper-slide {height:100%;}
	.swiper-container.bridge-page {min-width:129.2rem;}
	.sect {position:relative;height:100%;}
	#sect01 {background-color:#f5f0ec;}
	#sect02 {background:url('../images/bg_bridge_pc.png') 50% 50% no-repeat;background-size:cover;}
	#sect03 {
		background:#dc5e75;
		background:-moz-linear-gradient(-45deg,  #dc5e75 0%, #3699ff 100%);
		background:-webkit-linear-gradient(-45deg,  #dc5e75 0%,#3699ff 100%);
		background:linear-gradient(135deg,  #dc5e75 0%,#3699ff 100%);
		filter:progid:DXImageTransform.Microsoft.gradient( startColorstr='#dc5e75', endColorstr='#3699ff',GradientType=1 );
	}
	#sect04 {background:url('../images/bg_br_full.png') 50% 50% no-repeat;}
	#sect05 {background-color:#f6f6f6;}
	.sect .inner-container {
		display:-webkit-box;display:-moz-box;display:-ms-flexbox;display:-webkit-flex;display:flex;align-items:center;-webkit-align-items:center;
		-webkit-box-pack:center;-webkit-box-align:center;-moz-box-pack:center;-moz-box-align:center;-ms-box-pack:center;-ms-box-align:center;
		position:relative;overflow:hidden;width:129.2rem;height:100%;/*min-height:93.7rem;*/margin:0 auto;
	}
	.sect .inner-container.other-st {justify-content:center;-webkit-justify-content:center;}
	#sect03 .inner-container {min-height:93.7rem;}
	.cont-box {position:relative;width:100%;}
	.cont-box h2 {font-size:4.6rem;line-height:6rem;letter-spacing:-2px;color:#fff;font-weight:700;}
	.cont-box p {margin-top:1.6rem;margin-bottom:2.6rem;font-size:1.8rem;line-height:2.6rem;letter-spacing:-0.3px;font-weight:500;color:#fff;}
	.swiper-container.bridge-page .swiper-slide:first-of-type .sect .inner-container {min-height:1px;}
/* detail */
	#sect01 .ctrl-txt-box {position:relative;width:100%;padding-left:13rem;}
	#sect01 .ctrl-txt-box h1 img {display:block;width:46.9rem;height:13.8rem;}
	#sect01 .ctrl-txt-box p {margin-top:1.8rem;font-weight:300;color:#444;font-size:2.1rem;line-height:3.2rem;}
	#sect01 .ctrl-txt-box .inner-btn-module {width:46.8rem;margin-top:5rem;overflow:hidden;}
	#sect01 .ctrl-txt-box .inner-btn-module button, #sect01 .ctrl-txt-box .inner-btn-module a {float:left;overflow:hidden;width:22.4rem;height:5.8rem;border:1px solid #7a7a7a;border-radius:5.8rem;margin-left:2rem;text-align:center;}
	#sect01 .ctrl-txt-box .inner-btn-module button span, #sect01 .ctrl-txt-box .inner-btn-module a span {display:inline-block;overflow:hidden;height:5.6rem;padding-left:2.7rem;line-height:5.8rem;font-weight:500;color:#1e1e1e;font-size:1.6rem;vertical-align:top;background-repeat:no-repeat;background-position:0 50%;background-size:2.4rem;}
	#sect01 .ctrl-txt-box .inner-btn-module .app span {background-image:url('../images/ico_logo_googleplay.png');}
	#sect01 .ctrl-txt-box .inner-btn-module .web span {background-image:url('../images/ico_logo_apple.png');}
	#sect01 .ctrl-txt-box .inner-btn-module button:first-child, #sect01 .ctrl-txt-box .inner-btn-module a:first-child {margin-left:0;}
	#sect01 .ctrl-txt-box .m-vi {position:absolute;top:-13.7rem;right:0;width:63.4rem;height:66.6rem;}
	.free-coupon {position:relative;width:100%;padding-left:60.9rem;}
	.free-coupon h2 {margin-bottom:1.6rem;font-size:4.6rem;line-height:6rem;letter-spacing:-1.84px;color:#fff;font-weight:700;}
	.free-coupon .m-txt-box {position:absolute;left:8.3rem;top:10.4rem;}
	.free-coupon .s-txt, .free-coupon .m-txt {margin-top:0.7rem;font-size:1.8rem;line-height:2.6rem;letter-spacing:-0.27px;color:#fff;}
	.free-coupon .s-txt {color:rgba(255,255,255,0.7);}
	.free-coupon .m-vi-box {}
	.free-coupon .m-vi-box img {display:block;width:61.7rem;}
	.free-coupon ul {margin-top:3.3rem;}
	.free-coupon li {font-size:1.3rem;line-height:2rem;letter-spacing:-0.3px;color:rgba(255,255,255,0.8);}
	#sect03 .cont-box {text-align:center;}
	#sect03 .cont-box .m-vi {display:block;width:119.6rem;margin:0 auto;}
	#sect04 .cont-box {padding-left:13rem;}
	#sect04 .cont-box p {color:rgba(255,255,255,0.7);}
	#sect04 .cont-box .m-vi {position:absolute;top:-18.8rem;right:20.4rem;width:36rem;height:66.4rem;}
	#sect05 .cont-box {padding-left:80.6rem;}
	#sect05 .cont-box h2 {color:#1e1e1e;}
	#sect05 .cont-box p {color:#1e1e1e;}
	#sect05 .cont-box .m-vi {position:absolute;top:-18.8rem;left:10.7rem;width:57.8rem;height:66.4rem;}
	#sect05 .footer {position:absolute;left:0;right:0;bottom:0;z-index:2;display:flex;justify-content:center;padding:2rem 0 4rem 0;background-color:#f6f6f6;}
	#sect05 .footer-inner {position:relative;padding-left:13.4rem;}
	#sect05 .footer-inner::before {content:'';position:absolute;left:0;top:0;width:9.4rem;height:3.6rem;background:url('../images/img_footer_skt_logo.png') no-repeat;background-size:9.4rem 3.6rem;}
	#sect05 .footer-inner .m-txt {font-size:1.4rem;line-height:2.4rem;font-weight:500;color:#323246;letter-spacing:-0.5px;}
	#sect05 .footer-inner .s-txt {margin-top:0.6rem;font-size:1.2rem;line-height:1.5rem;font-weight:500;color:#848490;}

/* ----- RWD Resolution ----- */
@media (max-width:1292px){html {font-size:8px;line-height:14px;}}

.pop-inner {max-width: 50rem;}
